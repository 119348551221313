import {
  ForgottenPasswordPayload,
  IChangeForgottenPasswordPayload,
  LoginPayload,
} from 'app/interfaces/auth.interface'
import {User} from 'app/interfaces/user.interface'
import axios from 'axios'
import {AxiosResponse} from 'axios'

const createUser = (payload: Partial<User>) => {
  return axios.post('/api/auth/register', payload)
}

const setForgottenPasswordToken = (payload: ForgottenPasswordPayload) => {
  return axios.post('/api/auth/forgotten-password', payload)
}

const changeForgottenPassword = (payload: IChangeForgottenPasswordPayload) => {
  return axios.post('/api/auth/change-password', payload)
}

const loginUser = (payload: LoginPayload): Promise<AxiosResponse<User>> => {
  return axios.post('/api/auth/login', payload)
}

const passwordConfirm = (payload: LoginPayload) => {
  return axios.post('/api/auth/password-confirm', payload)
}

const getPasswordToken = (token: string) => {
  return axios.get(`/api/auth/password-token/${token}`)
}

const getRegistrationConfirmToken = (token: string) => {
  return axios.get(`/api/auth/registration-confirm/${token}`)
}

export const authService = {
  createUser,
  setForgottenPasswordToken,
  changeForgottenPassword,
  loginUser,
  passwordConfirm,
  getPasswordToken,
  getRegistrationConfirmToken,
}
