import JWTDecode from 'jwt-decode'
import axios from 'axios'
import {User} from '../interfaces/user.interface'

export const getLoggedInUser = () => {
  const accessToken = localStorage.getItem('accessToken')
  const refreshToken = localStorage.getItem('refreshToken')

  if (accessToken) {
    const user = {
      ...(JWTDecode(accessToken) as Partial<User>),
      accessToken,
    }

    const currentTime = new Date().getTime() / 1000
    if ((user.exp as number) > currentTime) {
      return Promise.resolve(user)
    }
    if (refreshToken && isTokenValid(refreshToken)) {
      return axios.post(
        '/api/auth/refresh-token',
        {},
        {headers: {Authorization: `Bearer ${refreshToken}`}}
      )
    }
  }
  return Promise.resolve(null)
}

export const isTokenValid = (currentToken: string | null): boolean => {
  const decodedToken = currentToken
    ? (JWTDecode(currentToken) as Partial<User>)
    : null
  const currentTime = new Date().getTime() / 1000
  if (((decodedToken?.exp as number) || 0) > currentTime) {
    return true
  }
  return false
}
