import {PageTemplate} from 'app/components/page-template/PageTemplate'
import React from 'react'
import {Alert} from 'react-bootstrap-v5'
import {useIntl} from 'react-intl'
import {PageTitle} from '_metronic/layout/core'
import FilesList from './components/FilesList'

const ShopPluginsPage = () => {
  const intl = useIntl()
  return (
    <PageTemplate>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({id: 'SHOP_PLUGINS'})}
      </PageTitle>
      <Alert variant='primary'>
        {intl.formatMessage({id: 'SHOP_PLUGINS_LIST_INFO'})}
      </Alert>
      <div className='d-flex justify-content-end'>
        <a href='shop-plugins/how-to-install'>
          {intl.formatMessage({id: 'HOW_TO_INSTALL_SHOP_PLUGINS'})}?
        </a>
      </div>
      <FilesList />
    </PageTemplate>
  )
}

export default ShopPluginsPage
