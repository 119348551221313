import axios, { AxiosRequestConfig } from 'axios'
import { ErrorDataFetch } from '../models/ErrorDataFetch';
import { AxiosResponse } from 'axios';
import { ICreateResetTwoFactor } from '../models/ICreateResetTwoFactor';

export default async function createResetTwoFactorAuthentication(email:string, accessToken:string):Promise<ICreateResetTwoFactor> {

    const config:AxiosRequestConfig  = {
        headers:{
            Authorization:`Bearer ${accessToken}`
        }
    }
 
    try {
        const data:AxiosResponse<ICreateResetTwoFactor> = await axios.post(`/api/auth/reset-two-factor/create`,
        { email }, 
        config
        )
               
        return data.data
    } catch (error:any) {
        if(error?.response?.data?.message && error?.response?.data?.code){           
            throw new ErrorDataFetch(error.response.data.message,error.response.data.code)
        }

        if(axios.isAxiosError(error))
        if(error.code){
            throw new ErrorDataFetch(error.message, +error.code)
        }else{
            throw new ErrorDataFetch(error.message, 404);
        }
        throw new ErrorDataFetch(`error fetch data`, 404)
    }
}