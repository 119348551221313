import {Redirect, Route, Switch} from 'react-router-dom'
import {lazy} from 'react'
import ServerStatistics from 'app/pages/statistics/server/ServerStatistics'

function StatisticsRoutes() {
  const RecognitionStatistics = lazy(
    () => import('../pages/statistics/recognition/RecognitionStatistics')
  )
  const UserRecognitionStatistics = lazy(
    () => import('../pages/statistics/recognition/UserRecognitionStatistics')
  )
  const AdminApplicationStatistics = lazy(
    () =>
      import('../pages/statistics/applications/AdminApplicationStatisticsPage')
  )

  return (
    <Switch>
      <Route
        exact
        path='/statistics/recognition'
        component={RecognitionStatistics}
      />
      <Route
        exact
        path='/statistics/recognition/:id'
        component={UserRecognitionStatistics}
      />
      <Route exact path='/statistics/server' component={ServerStatistics} />
      <Route
        exact
        path='/statistics/applications'
        component={AdminApplicationStatistics}
      />
      <Redirect to='/statistics/recognition' />
    </Switch>
  )
}

export default StatisticsRoutes
