import React from 'react'
import {StepCreateTwoFactor} from './createTwoFactorStepper'
import {useDispatch, useSelector} from 'react-redux'
import {RootDispatcher} from '../../store/root-dispatcher'
import {InitialState} from '../../store/root-reducer'
import {useIntl} from 'react-intl'

interface IWelcomTwoFactor {
  setSteps: React.Dispatch<React.SetStateAction<StepCreateTwoFactor>>
}

function WelcomeCreateTwoFactor({setSteps}: IWelcomTwoFactor) {
  const dispatch = useDispatch()
  const rootDispatcher = new RootDispatcher(dispatch)
  const user = useSelector((state: InitialState) => state.user)
  const intl = useIntl()
  function abortCreateTwoFactorHandler() {
    rootDispatcher.setIsNewUser(user, false)
  }
  return (
    <div className='d-flex flex-column'>
      <div className='d-flex flex-row'>
        <p className=''>
          {intl.formatMessage({
            id: 'CREATE_TWO_FACTOR_AUTHENTICATION_STEP_ONE',
          })}
        </p>
      </div>
      <div className='d-flex flex-column py-5'>
        <button
          onClick={() => {
            setSteps(`before`)
          }}
          className={`btn btn-lg btn-primary w-100 my-3`}
        >
          <span>
            {intl.formatMessage({
              id: 'NEXT_SECOND',
            })}
          </span>
        </button>
        <button
          type='button'
          className='btn btn-lg btn-light-primary w-100'
          onClick={() => {
            abortCreateTwoFactorHandler()
          }}
        >
          {intl.formatMessage({
            id: 'CANCEL',
          })}
        </button>
      </div>
    </div>
  )
}

export default WelcomeCreateTwoFactor
