import React from 'react'
import {Button} from 'react-bootstrap-v5'
import {useIntl} from 'react-intl'

const files = [
  {
    name: 'Magento',
    url: 'https://s3.eu-west-1.amazonaws.com/storage.recognition.test.kidihub.com/plugins/BakersZoo-recognitionmagentoplugin-625dc45fdc9b.zip',
  },
  {
    name: 'Prestashop',
    url: 'https://s3.eu-west-1.amazonaws.com/storage.recognition.test.kidihub.com/plugins/bakersrecognitionprestashopplugin.zip',
  },
]

const FilesList = () => {
  const intl = useIntl()

  const getFile = (url: string) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  return (
    <div>
      <table className='table table-row-gray-300 gy-5'>
        <thead>
          <tr className='fw-bolder fs-6 text-gray-800'>
            <th>{intl.formatMessage({id: 'NAME'})}</th>
            <th>URL</th>
          </tr>
        </thead>
        <tbody>
          {files.map((file) => (
            <tr key={file.name}>
              <td>{file.name}</td>
              <td>
                <Button size='sm' onClick={() => getFile(file.url)}>
                  {intl.formatMessage({id: 'DOWNLOAD'})}
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default FilesList
