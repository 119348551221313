/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {AsideMenuItem} from './AsideMenuItem'
import {InitialState} from 'app/store/root-reducer'
import {User} from 'app/interfaces/user.interface'
import {UserPermission} from 'app/interfaces/UserPermission'

export function AsideMenuMain() {
  const intl = useIntl()
  const user: User = useSelector<InitialState>(({user}) => user) as User

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      {user.isAdmin && (
        <>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                {intl.formatMessage({id: 'USERS'})}
              </span>
            </div>
          </div>
          <AsideMenuItem
            to='/users'
            icon='/media/icons/duotune/communication/com006.svg'
            title={intl.formatMessage({id: 'USERS_LIST'})}
            fontIcon='bi-person'
          />
        </>
      )}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            {intl.formatMessage({id: 'ORGANIZATIONS'})}
          </span>
        </div>
      </div>
      {!user.isAdmin && (
        <>
          <AsideMenuItem
            to='/organizations'
            icon='/media/icons/duotune/layouts/lay009.svg'
            title={intl.formatMessage({id: 'ORGANIZATIONS'})}
            fontIcon='bi-archive'
          />
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                {intl.formatMessage({id: 'APPLICATIONS'})}
              </span>
            </div>
          </div>
        </>
      )}
      <AsideMenuItem
        to='/applications'
        icon='/media/icons/duotune/general/gen022.svg'
        title={intl.formatMessage({id: 'APPLICATIONS'})}
        fontIcon='bi-archive'
      />
      {user.isAdmin && (
        <>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                {intl.formatMessage({id: 'QED_STATUS_TITLE'})}
              </span>
            </div>
          </div>
          <AsideMenuItem
            to='/qedstatus'
            icon='/media/icons/duotune/arrows/arr070.svg'
            title={intl.formatMessage({id: 'QED_STATUS_SUB_TITLE'})}
            fontIcon='bi-person'
          />
          <AsideMenuItem
            to='/qedcolors/?pageNumber=1&itemsPerPage=50'
            icon='/media/icons/duotune/art/art007.svg'
            title={intl.formatMessage({id: 'COLORS'})}
            fontIcon='bi-person'
          />
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                {intl.formatMessage({id: 'SURVEYS'})}
              </span>
            </div>
          </div>
          <AsideMenuItem
            to='/surveys'
            icon='/media/icons/duotune/files/fil016.svg'
            title={intl.formatMessage({id: 'SURVEYS_LIST'})}
            fontIcon='bi-person'
          />
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                {`LOGGER`}
              </span>
            </div>
          </div>
          <AsideMenuItem
            to='/logger'
            icon='media/icons/duotune/abstract/abs015.svg'
            title={`Logger`}
            fontIcon='bi-person'
          />
        </>
      )}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            {intl.formatMessage({id: 'STATISTICS'})}
          </span>
        </div>
      </div>
      <AsideMenuItem
        to='/statistics/recognition'
        icon='/media/icons/duotune/arrows/arr070.svg'
        title={intl.formatMessage({id: 'STATISTICS'})}
        fontIcon='bi-person'
      />
      {(user.isAdmin || user.permission === UserPermission.OWNER) && (
        <>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                {intl.formatMessage({id: 'SHOP_PLUGINS'})}
              </span>
            </div>
          </div>
          <AsideMenuItem
            to='/shop-plugins'
            icon='/media/icons/duotune/arrows/arr070.svg'
            title={intl.formatMessage({id: 'SHOP_PLUGINS'})}
            fontIcon='bi-person'
          />
        </>
      )}
      {user.permission === UserPermission.OWNER && (
        <>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                {intl.formatMessage({id: 'CONTACT'})}
              </span>
            </div>
          </div>
          <AsideMenuItem
            to='/contact'
            icon='/media/icons/duotune/arrows/arr070.svg'
            title={intl.formatMessage({id: 'CONTACT'})}
            fontIcon='bi-person'
          />
        </>
      )}
      {user.isAdmin && (
        <>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                {intl.formatMessage({id: 'TESTS'})}
              </span>
            </div>
          </div>
          <AsideMenuItem
            to='/recognition-tests'
            icon='/media/icons/duotune/arrows/arr070.svg'
            title={intl.formatMessage({id: 'TESTS'})}
            fontIcon='bi-person'
          />
        </>
      )}
    </>
  )
}
