import React, {FC, useMemo} from 'react'
import ApexCharts from 'react-apexcharts'
import {useIntl} from 'react-intl'
import {
  getHoursFromHourlyCountsResult,
  parseHourlyCountsDataResultToApexChartSeries,
} from 'app/utils/statistics/statistics.utils'
import {IServerUnavailabilityByHour} from 'app/interfaces/statistics/server-statistics.interface'

interface IProps {
  serverUnavailabilityByHour?: IServerUnavailabilityByHour[]
}

const ServerUnavailabilityByHoursChart: FC<IProps> = ({
  serverUnavailabilityByHour,
}) => {
  const intl = useIntl()
  const hours = useMemo(
    () => getHoursFromHourlyCountsResult(serverUnavailabilityByHour),
    [serverUnavailabilityByHour]
  )
  const chartData = useMemo(
    () =>
      parseHourlyCountsDataResultToApexChartSeries(serverUnavailabilityByHour),
    [serverUnavailabilityByHour]
  )

  return (
    <ApexCharts
      options={{
        chart: {
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            borderRadius: 5,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: hours,
          title: {
            text: intl.formatMessage({id: 'HOUR'}),
            style: {fontSize: '15', fontWeight: 'normal'},
          },
        },
        yaxis: {
          title: {
            text: intl.formatMessage({id: 'ALERTS'}),
            style: {fontSize: '15', fontWeight: 'normal'},
          },
        },
      }}
      series={[
        {
          name: intl.formatMessage({id: 'ALERTS'}),
          data: chartData,
        },
      ]}
      type='bar'
      height={300}
    />
  )
}

export default ServerUnavailabilityByHoursChart
