import React from 'react'
import {LogoWrapper} from '../Survey.styles'

interface IProps {
  url?: string
}

const KidihubLogo: React.FC<IProps> = ({url}) => {
  return (
    <div>
      <LogoWrapper>{url && <img src={url} alt='kidihub_logo' />}</LogoWrapper>
    </div>
  )
}

export default KidihubLogo
