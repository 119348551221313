/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {useHistory} from 'react-router'
import {authService} from 'app/services/auth.service'
import {useToasts} from 'react-toast-notifications'
import {useIntl} from 'react-intl'

export const ConfirmRegistration = () => {
  const history = useHistory()
  const intl = useIntl()
  const {addToast} = useToasts()

  useEffect(() => {
    authService
      .getRegistrationConfirmToken(
        (window as any).location.href.split('/').pop()
      )
      .then((tokenResponse) => {
        addToast(
          intl.formatMessage({
            id: 'YOUR_REGISTRATION_HAS_BEEN_CONFIRMED_YOU_CAN_NOW_LOG_IN',
          }),
          {
            appearance: 'success',
          }
        )
        history.push('/auth/login')
      })
      .catch(() => {
        addToast(
          intl.formatMessage({
            id: 'YOUR_REGISTRATION_TOKEN_HAS_EXPIRED',
          }),
          {appearance: 'error'}
        )
        history.push('/auth/login')
      })
  }, [history, addToast])

  return (
    <div>
      {intl.formatMessage({
        id: 'REGISTRATION_CONFIRMED',
      })}
    </div>
  )
}
