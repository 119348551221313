/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {Language} from 'app/enums/language.enum'
import {setLanguage, useLang} from 'app/i18n'
import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch} from 'react-redux'
import {RootDispatcher} from 'app/store/root-dispatcher'
import {toAbsoluteUrl} from '../../../helpers'

const languages = [
  {
    lang: 'en',
    name: 'English',
    flag: toAbsoluteUrl('/media/flags/united-states.svg'),
  },
  {
    lang: 'pl',
    name: 'Polski',
    flag: toAbsoluteUrl('/media/flags/poland.svg'),
  },
]

const Languages: FC = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const lang = useLang()
  const currentLanguage = languages.find((x) => x.lang === lang)
  const [isLangMenu, setIsLangMenu] = useState(false)

  useEffect(() => {
    const rootDispatcher = new RootDispatcher(dispatch)
    const savedLanguage: Language =
      (localStorage.getItem('lang') as Language) || Language.ENGLISH
    rootDispatcher.setLanguage(savedLanguage)
  }, [dispatch])

  const setCurrentLanuage = (language: any) => {
    const rootDispatcher = new RootDispatcher(dispatch)
    rootDispatcher.setLanguage(language.lang)
    setLanguage(language.lang)
    localStorage.setItem('lang', language.lang)
  }

  return (
    <div
      className='menu-item px-5'
      data-kt-menu-trigger='hover'
      data-kt-menu-placement='left-start'
      data-kt-menu-flip='bottom'
    >
      <a
        href='#'
        className='menu-link px-5'
        onClick={() => setIsLangMenu(true)}
      >
        <span className='menu-title position-relative'>
          {intl.formatMessage({id: 'LANGUAGE'})}
          <span className='fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0'>
            {currentLanguage?.name}{' '}
            <img
              className='w-15px h-15px rounded-1 ms-2'
              src={currentLanguage?.flag}
              alt=''
            />
          </span>
        </span>
      </a>

      {isLangMenu && (
        <div className='w-175px py-4'>
          {languages.map((l) => (
            <div
              className='menu-item px-3'
              key={l.lang}
              onClick={() => {
                setCurrentLanuage(l)
                setIsLangMenu(false)
              }}
            >
              <a
                href='#'
                className={clsx('menu-link d-flex px-5', {
                  active: l.lang === currentLanguage?.lang,
                })}
              >
                <span className='symbol symbol-20px me-4'>
                  <img className='rounded-1' src={l.flag} alt='metronic' />
                </span>
                {l.name}
              </a>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export {Languages}
