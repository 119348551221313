import {Switch, Route, useHistory} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {PrivateRoutes} from './Private.routes'
import {InitialState} from '../store/root-reducer'
import {Errors} from '../pages/errors/Errors'
import {Auth} from '../pages/auth/Auth'
import {Error404} from 'app/pages/errors/Error404'
import TwoFactorPage from '../pages/twoFactor/TwoFactorPage'
import CreateTwoFactorPage from '../pages/twoFactor/CreateTwoFactorPage'
import DocRoutes from './Doc.routes'
import SurveyRoutes from './Survey.routes'
import {UserPermission} from '../interfaces/UserPermission'
import {logoutUser} from '../controllers/logoutUser'
import {RootDispatcher} from '../store/root-dispatcher'
import PrivacyPolicyPage from 'app/pages/privacy-policy/PrivacyPolicyPage'

export function Routes() {
  const user = useSelector((state: InitialState) => state.user)
  const dispatch = useDispatch()
  const history = useHistory()
  const rootDispatcher = new RootDispatcher(dispatch)
  if (user && user.permission === UserPermission.EMPLOYEE) {
    logoutUser(rootDispatcher)
  }
  return (
    <Switch>
      <Route path='/error/404' component={Error404} />
      <Route path='/privacy-policy' component={PrivacyPolicyPage} />
      <Route path='/doc'>
        <DocRoutes />
      </Route>
      <Route path='/survey'>
        <SurveyRoutes />
      </Route>
      {user === null && (
        <Route>
          <Auth />
        </Route>
      )}
      {user &&
        user.permission === UserPermission.EMPLOYEE &&
        history.push('/notexisitingurl')}
      {user && user.isNewUser && (
        <Route>
          <CreateTwoFactorPage />
        </Route>
      )}
      {user && user.isUsedTwoFactor && !user.isVerifyTwoFactor && (
        <Route>
          <TwoFactorPage />
        </Route>
      )}

      {user &&
        user.isNewUser === false &&
        user.isUsedTwoFactor === true &&
        user.isVerifyTwoFactor === true && (
          <MasterLayout>
            <PrivateRoutes user={user} />
          </MasterLayout>
        )}
      {user &&
        user.isNewUser === false &&
        user.isUsedTwoFactor === false &&
        user.isVerifyTwoFactor === false && (
          <MasterLayout>
            <PrivateRoutes user={user} />
          </MasterLayout>
        )}
      <Route path='/error' component={Errors} />
    </Switch>
  )
}
