import {IServerStatistics} from 'app/interfaces/statistics/server-statistics.interface'
import axios, {AxiosResponse} from 'axios'

interface IServerStatisticsSearch {
  dateFrom?: Date | null
  dateTo?: Date | null
}

const getServerStatistics = (
  params?: IServerStatisticsSearch
): Promise<AxiosResponse<IServerStatistics>> => {
  return axios.get('/api/server-statistics/get-all', {params})
}

export const serverStatisticsService = {
  getServerStatistics,
}
