import React from 'react'
import AuthLayout from './../../layouts/AuthLayout'
import AuthScreen from './../../screens/AuthScreen'
import {useSelector} from 'react-redux'
import {InitialState} from '../../store/root-reducer'
import CreateTwoFactorStepper from '../../components/twoFactor/createTwoFactorStepper'
import {useIntl} from 'react-intl'

function CreateTwoFactorPage() {
  const userState = useSelector((state: InitialState) => state.user)
  const intl = useIntl()
  if (userState === null || userState === undefined) {
    // TODO: [KBCONSOLE-79] logout and remomve local sesion/cookies/redux user and return error component
    return <div>404 | Error no data to make tow factor authentication</div>
  }

  return (
    <AuthLayout>
      <AuthScreen
        title={intl.formatMessage({
          id: 'CREATE_TWO_FACTOR_AUTHENTICATION_TITLE',
        })}
        subtitle={`${userState.email}`}
      >
        <CreateTwoFactorStepper />
      </AuthScreen>
    </AuthLayout>
  )
}

export default CreateTwoFactorPage
