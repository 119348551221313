/* eslint-disable jsx-a11y/anchor-is-valid */
import useOnClickOutside from 'app/hooks/useOnClickOutside'
import {LatestNotifications} from 'app/interfaces/notification.interface'
import {notificationService} from 'app/services/notification.service'
import {RootDispatcher} from 'app/store/root-dispatcher'
import clsx from 'clsx'
import moment from 'moment'
import React, {FC, useRef} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch} from 'react-redux'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {NotificationsMenuWrapper} from './HeaderNotificationsMenu.styles'
import {useQueryClient} from 'react-query'

interface StateProps {
  notifications: LatestNotifications | null
  hideMenu: () => void
}

const HeaderNotificationsMenu: FC<StateProps> = ({notifications, hideMenu}) => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const queryClient = useQueryClient()
  const ref = useRef() as React.MutableRefObject<HTMLInputElement>
  useOnClickOutside(ref, hideMenu)

  const markAsRead = async (id: string, isRead: boolean) => {
    const rootDispatcher = new RootDispatcher(dispatch)
    if (!isRead) {
      await notificationService.updateNotification(id, {markedAsRead: true})
      const updatedNotificationsContent = (
        notifications as LatestNotifications
      ).content.map((notification) => ({
        ...notification,
        markedAsRead:
          notification._id === id ? true : notification.markedAsRead,
      }))
      rootDispatcher.setNotifications({
        content: updatedNotificationsContent,
        unread: (notifications as LatestNotifications).unread - 1,
      })
    }
  }

  return (
    <NotificationsMenuWrapper
      ref={ref}
      className='menu menu-sub menu-sub-dropdown show menu-column w-350px w-lg-375px'
    >
      <div
        className='d-flex flex-column bgi-no-repeat rounded-top'
        style={{
          backgroundImage: `url('${toAbsoluteUrl(
            '/media/misc/pattern-1.jpg'
          )}')`,
        }}
      >
        <h3 className='text-white fw-bold px-9 mt-10 mb-6'>
          {intl.formatMessage({id: 'NOTIFICATIONS'})}{' '}
          {notifications?.unread ? (
            <span className='fs-8 opacity-75 ps-3'>
              {notifications?.unread}{' '}
              {intl.formatMessage({id: 'UNREAD'}).toLowerCase()}
            </span>
          ) : null}
        </h3>
      </div>

      <div className='tab-content'>
        <div
          className='tab-pane fade show active'
          id='kt_topbar_notifications_2'
          role='tabpanel'
        >
          <div className='scroll-y mh-325px my-5 px-8'>
            {notifications?.content?.map((notification, index) => (
              <div key={`alert${index}`} className='d-flex flex-stack py-4'>
                <div className='d-flex align-items-center'>
                  <div className='symbol symbol-35px me-4'>
                    <span
                      className={clsx(
                        'symbol-label',
                        `bg-light-${notification.type}`
                      )}
                    >
                      <KTSVG
                        path={`/media/icons/duotune/maps/map001.svg`}
                        className={`svg-icon-2 svg-icon-${notification.type}`}
                      />
                    </span>
                  </div>

                  <div className='mb-0 me-2'>
                    <div
                      className='fs-6 text-gray-800 text-hover-primary fw-bolder'
                      onClick={() => {
                        markAsRead(notification._id, notification.markedAsRead)
                        queryClient.invalidateQueries(`get-notifications`)
                      }}
                      style={{
                        cursor: notification.markedAsRead
                          ? 'default'
                          : 'pointer',
                      }}
                    >
                      {intl.formatMessage({id: notification.title})}
                      {!notification.markedAsRead && (
                        <div
                          className='bg-success rounded-circle border border-3 border-white h-10px w-10px'
                          style={{display: 'inline-block'}}
                        ></div>
                      )}
                    </div>
                    <div className='text-gray-400 fs-7'>
                      {intl.formatMessage({id: notification.text})}
                    </div>
                  </div>
                </div>

                <span className='badge badge-light fs-8'>
                  {moment(notification.createdAt).fromNow()}
                </span>
              </div>
            ))}
          </div>

          <div className='py-3 text-center border-top'>
            <Link
              to='/account/activity'
              className='btn btn-color-gray-600 btn-active-color-primary'
              onClick={hideMenu}
            >
              {intl.formatMessage({id: 'VIEW_ALL'})}{' '}
              <KTSVG
                path='/media/icons/duotune/arrows/arr064.svg'
                className='svg-icon-5'
              />
            </Link>
          </div>
        </div>
      </div>
    </NotificationsMenuWrapper>
  )
}

export {HeaderNotificationsMenu}
