import TwoFactorForm from '../../forms/twoFactorCodeForm'
import AuthScreen from '../../screens/AuthScreen'
import AuthLayout from '../../layouts/AuthLayout'
import {ITokenTwoFactorCode} from '../../interfaces/ITokenTwoFactor'
import {useSelector, useDispatch} from 'react-redux'
import {InitialState} from '../../store/root-reducer'
import getVerifyTwoFactorAuthentication from 'app/controllers/getVerifyTwoFactor'
import {RootDispatcher} from '../../store/root-dispatcher'
import {useState} from 'react'
import {Button} from 'react-bootstrap-v5'
import CreateResetTwoFactor from '../../components/twoFactor/createResetTwoFactor'
import {logoutUser} from '../../controllers/logoutUser'
import {useToasts} from 'react-toast-notifications'
import {useIntl} from 'react-intl'

function TwoFactorPage() {
  const userState = useSelector((state: InitialState) => state.user)
  const dispatch = useDispatch()
  const intl = useIntl()
  const rootDispatcher = new RootDispatcher(dispatch)
  const {addToast} = useToasts()

  const [showCreateResetQRCode, setShowCreateResetQRCode] =
    useState<boolean>(false)

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [showButtonBackOrReset, setShowButtonBackOrReset] =
    useState<boolean>(true)

  if (userState === null || userState === undefined) {
    // TODO: [KBCONSOLE-79] logout and remomve local sesion/cookies/redux user and return error component
    return <div>404 | Error no data to make tow factor authentication</div>
  }

  const accessToken = localStorage.getItem(`accessToken`)

  function logoutUserHandler() {
    logoutUser(rootDispatcher)
  }

  function tokenHandleSubmit(
    values: ITokenTwoFactorCode,
    {
      setErrors,
    }: {
      setErrors: Function
    }
  ) {
    setIsLoading(true)
    if (accessToken === null) {
      setErrors({
        token: intl.formatMessage({id: `INCORRECT_CREDENTIALS`}),
      })
      addToast(
        intl.formatMessage({id: `ERROR`}) +
          `: ` +
          intl.formatMessage({id: `INCORRECT_CREDENTIALS`}),
        {appearance: 'error'}
      )
      setIsLoading(false)
      return
    }
    getVerifyTwoFactorAuthentication(values.token, accessToken)
      .then((data) => {
        setIsLoading(false)
        const {isVerifyTwoFactor} = data

        if (userState === null || userState === undefined) {
          setErrors({token: intl.formatMessage({id: `INCORRECT_CREDENTIALS`})})
          addToast(
            intl.formatMessage({id: `ERROR`}) +
              `: ` +
              intl.formatMessage({id: `INCORRECT_CREDENTIALS`}),
            {appearance: 'error'}
          )
          return
        }

        switch (isVerifyTwoFactor) {
          case false:
            // TODO: Transtate error from forms
            setErrors({token: `INCORRECT_TOKEN`})
            addToast(
              intl.formatMessage({id: `ERROR`}) +
                `: ` +
                intl.formatMessage({id: `INCORRECT_CREDENTIALS`}),
              {appearance: 'error'}
            )
            break
          case 'notOnTime':
            // TODO: Transtate error from forms
            setErrors({token: `TOKEN_IS_NOT_ON_TIME`})
            addToast(
              intl.formatMessage({id: `ERROR`}) +
                `: ` +
                intl.formatMessage({id: `INCORRECT_CREDENTIALS`}),
              {appearance: 'error'}
            )
            break
          case true:
            localStorage.setItem(`accessToken`, data.accessToken)
            rootDispatcher.setUser(userState, true)
            break

          default:
            // TODO: Transtate error from forms
            setErrors({token: `unknown error 1`})
            addToast(
              intl.formatMessage({id: `ERROR`}) +
                `: ` +
                intl.formatMessage({id: `INCORRECT_CREDENTIALS`}),
              {appearance: 'error'}
            )
            logoutUserHandler()
            break
        }
      })
      .catch((error) => {
        setIsLoading(false)
        // TODO: Transtate error from forms
        setErrors({token: `unknown error 2`})
        addToast(
          intl.formatMessage({id: `ERROR`}) +
            `: ` +
            intl.formatMessage({id: `TIME_OUT`}),
          {appearance: 'error'}
        )
        logoutUserHandler()
      })
  }
  return (
    <AuthLayout>
      <AuthScreen
        title={intl.formatMessage({id: `TWO_FACTOR_AUTHENTICATION`})}
        subtitle={
          !showCreateResetQRCode
            ? `${userState.email}`
            : `${intl.formatMessage({id: `RESET_QR_CODE`})}`
        }
      >
        {!showCreateResetQRCode && (
          <TwoFactorForm
            tokenHandleSubmit={tokenHandleSubmit}
            logoutUserHandler={logoutUserHandler}
            isLoading={isLoading}
          />
        )}
        {showCreateResetQRCode && (
          <CreateResetTwoFactor
            accessToken={accessToken}
            isLoading={isLoading}
            logoutUserHandler={logoutUserHandler}
            setIsLoading={setIsLoading}
            setShowButtonBackOrReset={setShowButtonBackOrReset}
          />
        )}
        <div className='text-gray-400 fw-bold fs-4 mt-2'>
          {showButtonBackOrReset && (
            <Button
              className='link-primary fw-bolder'
              variant='link'
              onClick={() => setShowCreateResetQRCode((prev) => !prev)}
            >
              {!showCreateResetQRCode
                ? intl.formatMessage({id: `RESET_YOUR_QR_CODE`})
                : intl.formatMessage({id: `BACK`})}
            </Button>
          )}
        </div>
      </AuthScreen>
    </AuthLayout>
  )
}

export default TwoFactorPage
