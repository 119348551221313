import {RootDispatcher} from 'app/store/root-dispatcher'
import {authChannel} from '../App'
import logoutServer from './logoutServer'
import {clearLocalStorageWithoutLanguage} from '../utils/clearLocalStorageWithoutLanguage'

export function logoutUser(rootDispatcher: RootDispatcher) {
  logoutServer()
    .then(() => {
      clearLocalStorageWithoutLanguage()
    })
    .catch(() => {
      clearLocalStorageWithoutLanguage()
    })
  rootDispatcher.setUser(null, false)
  authChannel.postMessage({cmd: 'logout'})
}
