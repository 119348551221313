import {InitialState} from '../../store/root-reducer'
import {useSelector, useDispatch} from 'react-redux'
import {useIntl} from 'react-intl'
import {RootDispatcher} from '../../store/root-dispatcher'
import {logoutUser} from '../../controllers/logoutUser'
import {useHistory} from 'react-router-dom'

interface IResetTwoFactorMessage {
  typeMessage: 'create' | 'confirm'
}

function ResetTwoFactorMessage({typeMessage}: IResetTwoFactorMessage) {
  const userState = useSelector((state: InitialState) => state.user)
  const history = useHistory()
  const dispatch = useDispatch()
  const rootDispatcher = new RootDispatcher(dispatch)

  const intl = useIntl()
  function logoutUserHandler() {
    if (typeMessage === 'create') {
      logoutUser(rootDispatcher)
    } else if (typeMessage === 'confirm') {
      history.push('/')
    }
  }
  return (
    <div className='d-flex flex-column my-15'>
      {typeMessage === 'create' && (
        <div className='d-block text-center'>
          <span className=''>
            {intl.formatMessage({id: `RESET_2FA_BODY_1`})}
            <strong> {userState?.email} </strong>
            <br />
            {intl.formatMessage({id: `RESET_2FA_BODY_2`})}
          </span>
        </div>
      )}
      {typeMessage === 'confirm' && (
        <div className='d-block text-center'>
          <div className='my-2'>
            <span className='my-5'>
              {' '}
              {intl.formatMessage({id: `RESET_2FA_SUCCESS_1`})}
            </span>
          </div>
          {/* <br /> */}
          <div className='my-2'>
            <span className='my-5'>
              {' '}
              {intl.formatMessage({id: `RESET_2FA_SUCCESS_2`})}
            </span>
          </div>
        </div>
      )}
      <button
        type='button'
        className='btn btn-lg btn-light-primary w-100 mb-5 mt-15'
        onClick={() => {
          logoutUserHandler()
        }}
      >
        {intl.formatMessage({id: `BACK`})}
      </button>
    </div>
  )
}

export default ResetTwoFactorMessage
