import React, {Suspense, useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {BrowserRouter} from 'react-router-dom'
import {useHistory} from 'react-router'
import {getLoggedInUser} from './helpers/auth.helper'
import interceptor from './interceptor'
import {RootDispatcher} from './store/root-dispatcher'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {Routes} from './routing/Routes'
import {I18nProvider} from './i18n'
import {userService} from './services/user.service'
import {ReactQueryDevtools} from 'react-query/devtools'
import {QueryClient, QueryClientProvider} from 'react-query'
import {BroadcastChannel} from 'broadcast-channel'
import {clearLocalStorageWithoutLanguage} from './utils/clearLocalStorageWithoutLanguage'

type Props = {
  basename: string
}

export const authChannel = new BroadcastChannel('auth')

const App: React.FC<Props> = ({basename}) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const queryClient = new QueryClient()

  const logOut = () => {
    const rootDispatcher = new RootDispatcher(dispatch)
    rootDispatcher.setUser(null, false)
  }

  authChannel.onmessage = (e) => {
    if (e.cmd === 'logout') {
      logOut()
    }
  }

  useEffect(() => {
    const rootDispatcher = new RootDispatcher(dispatch)
    ;(getLoggedInUser() as Promise<any>)
      .then((response: any) => {
        const loggedInUser = response?.data || response
        if (loggedInUser) {
          localStorage.setItem('accessToken', loggedInUser.accessToken)
          interceptor(loggedInUser.accessToken, history)
          return userService
            .getSelf()
            .then((user) => {
              const updatedUser = {...loggedInUser, ...user.data}
              rootDispatcher.setUser(updatedUser, updatedUser.isVerifyTwoFactor)
              return true
            })
            .catch((err) => {
              rootDispatcher.setUser(null, false)
              clearLocalStorageWithoutLanguage()
              return false
            })
        } else {
          rootDispatcher.setUser(null, false)
          clearLocalStorageWithoutLanguage()
          return false
        }
      })
      .catch((err) => {
        rootDispatcher.setUser(null, false)
        clearLocalStorageWithoutLanguage()
        return false
      })
  }, [dispatch, history])

  return (
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={<LayoutSplashScreen />}>
        <BrowserRouter basename={basename}>
          <I18nProvider>
            <LayoutProvider>
              <Routes />
            </LayoutProvider>
          </I18nProvider>
        </BrowserRouter>
      </Suspense>
      <ReactQueryDevtools position='bottom-right' initialIsOpen={false} />
    </QueryClientProvider>
  )
}

export {App}
