import axios from 'axios'
import {isTokenValid} from './helpers/auth.helper'
import {store} from './store'
import {RootDispatcher} from './store/root-dispatcher'
import {AxiosResponse} from 'axios'
import {clearLocalStorageWithoutLanguage} from './utils/clearLocalStorageWithoutLanguage'

const acceptPathToRefreshToken = [
  '/api/auth/logout',
  '/api/auth/delete',
  '/api/auth/two-factor/delete',
  '/api/auth/addemployee',
]

function isUrlHaveAccessToRefreshTokenAndJoinAccessToken(
  url: string | undefined
): boolean {
  if (url === undefined || url === null) {
    return false
  }
  return acceptPathToRefreshToken.includes(url)
}

const interceptor = (accessTokenParam: string | null, history: any = null) => {
  axios.interceptors.request.use(
    async (conf) => {
      const accessToken =
        localStorage.getItem('accessToken') || accessTokenParam
      const refreshToken = localStorage.getItem('refreshToken')

      const tokenValid = isTokenValid(accessToken)

      if (isUrlHaveAccessToRefreshTokenAndJoinAccessToken(conf.url)) {
        if (accessToken && !tokenValid && refreshToken) {
          const refreshAccessToken: AxiosResponse<{accessToken: string}> =
            await axios.post(
              '/api/auth/refresh-token',
              {},
              {
                headers: {Authorization: `Bearer ${refreshToken}`},
              }
            )

          conf.headers[
            'Authorization'
          ] = `Bearer ${refreshAccessToken.data.accessToken}`
          return conf
        }
        conf.headers['Authorization'] = `Bearer ${accessToken}`

        return conf
      } else if (conf.url && conf.url.indexOf('/api/auth') > -1) {
        return conf
      } else if (conf.url && conf.url.indexOf('refresh-token') > -1) {
        return conf
      } else if (accessToken && tokenValid) {
        conf.headers['Authorization'] = `Bearer ${accessToken}`
        return conf
      } else if (accessToken && !tokenValid && refreshToken) {
        const refreshAccessToken: AxiosResponse<{accessToken: string}> =
          await axios.post(
            '/api/auth/refresh-token',
            {},
            {headers: {Authorization: `Bearer ${refreshToken}`}}
          )
        conf.headers[
          'Authorization'
        ] = `Bearer ${refreshAccessToken.data.accessToken}`

        //TODO: [KBCONSOLE-90] decide where is accessToken and refreshToken
        localStorage.setItem('accessToken', refreshAccessToken.data.accessToken)
        return conf
      }
      return conf
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  axios.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      const rootDispatcher = new RootDispatcher((store as any).dispatch)
      if (
        error.response?.status === 401 ||
        error.response?.status === 403 ||
        error.response?.status === 404 ||
        error.response?.status === 500
      ) {
        rootDispatcher.setUser(null, false)
        clearLocalStorageWithoutLanguage()
      } else if (
        error.response?.config.url.indexOf('/api/auth/refresh-token') > -1
      ) {
        rootDispatcher.setUser(null, false)
        clearLocalStorageWithoutLanguage()
      }
      return Promise.reject(error)
    }
  )
}

export default interceptor
