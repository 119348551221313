import React, {useEffect} from 'react'

const useOnClickOutside = (ref: any, onClickOutside: () => void) => {
  useEffect(() => {
    const handleClick = (e: MouseEvent | TouchEvent) => {
      if (!ref.current || ref.current.contains(e?.target)) return
      onClickOutside()
    }

    document.addEventListener('mousedown', handleClick)
    document.addEventListener('touchstart', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
      document.removeEventListener('touchstart', handleClick)
    }
  }, [ref, onClickOutside])
}

export default useOnClickOutside
