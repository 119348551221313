import React, {useState} from 'react'
import WelcomeCreateTwoFactor from './welcomeCreateTwoFactor'
import BeforeCreateTwoFactor from './beforeCreateTwoFactor'
import CodeQRCreateTwoFactor from './codeQRCreateTwoFactor'

export type StepCreateTwoFactor = `welcome` | `before` | `generate` | `exit`

function CreateTwoFactorStepper() {
  const [steps, setSteps] = useState<StepCreateTwoFactor>(`welcome`)

  if (steps === `before`) return <BeforeCreateTwoFactor setSteps={setSteps} />
  if (steps === `generate`) return <CodeQRCreateTwoFactor />
  if (steps === `exit`) return <div>{`exit`}</div>

  return <WelcomeCreateTwoFactor setSteps={setSteps} />
}

export default CreateTwoFactorStepper
