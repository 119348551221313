import React, {FC} from 'react'
import {Card, Col} from 'react-bootstrap-v5'
import {KTSVG} from '_metronic/helpers'

interface IProps {
  title: string
  subtitle?: string
  icon: string
}

const StatisticsCard: FC<IProps> = ({title, subtitle, children, icon}) => {
  return (
    <Card className='h-100 position-relative'>
      <Card.Header className='border-0 py-5 row justify-content-between'>
        <Col sm={11}>
          <Card.Title className='align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>{title}</span>

            <span className='text-muted fw-bold fs-7'>{subtitle}</span>
          </Card.Title>
        </Col>
        <Col sm={1}>
          <div
            className='primary card-toolbar position-absolute'
            style={{top: '15px', right: '25px'}}
          >
            <KTSVG path={icon} className='svg-icon-2 svg-icon-primary' />
          </div>
        </Col>
      </Card.Header>
      <Card.Body className='pt-0 d-flex flex-column justify-content-around'>
        {children}
      </Card.Body>
    </Card>
  )
}

export default StatisticsCard
