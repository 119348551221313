/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {useLayout} from '../core'
import version from '../../../version.json'
import {useIntl} from 'react-intl'

const Footer: FC = () => {
  const {classes} = useLayout()
  const intl = useIntl()
  return (
    <div className='footer py-4 d-flex flex-lg-column' id='kt_footer'>
      {/* begin::Container */}
      <div
        className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between `}
      >
        <div className='w-100 text-center'>
          <small>
            version:{' '}
            {`${version.major}.${version.minor}.${version.revision}: ${
              process.env.REACT_APP_DEPLOY_NUMBER || version.buildNumber
            }`}{' '}
            |{' '}
            <a href='/privacy-policy' target={'_blank'}>
              {intl.formatMessage({
                id: `PRIVACY_POLICY`,
              })}
            </a>
          </small>
        </div>
      </div>
      {/* end::Container */}
    </div>
  )
}

export {Footer}
