/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      {/* <AsideMenuItem
        to='/builder'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Layout Builder'
        fontIcon='bi-layers'
      /> */}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            Rest API
          </span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/doc/recognition'
        title='Recognition'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItem
          to='/doc/recognition/config/restapi'
          title='RestApi'
          hasBullet={true}
        />

        {/* <AsideMenuItem
          to='/doc/recognito/config/prestashop'
          title='PrestaShop'
          hasBullet={true}
        />
        <AsideMenuItem
          to='/doc/recognito/config/kidihub'
          title='Kidihub'
          hasBullet={true}
        />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/doc/error'
        title='Errors'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen040.svg'
      >
        <AsideMenuItem to='/doc/error/404' title='Error 404' hasBullet={true} />
        <AsideMenuItem to='/doc/error/500' title='Error 500' hasBullet={true} /> */}
      </AsideMenuItemWithSub>
    </>
  )
}
