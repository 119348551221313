import {
  IRecognitionDailyLoad,
  IRecognitionHourlyLoad,
} from 'app/interfaces/statistics/recognition-statistics.interface'
import {
  IServerUnavailabilityByDay,
  IServerUnavailabilityByHour,
} from 'app/interfaces/statistics/server-statistics.interface'

export const parseDailyCountsDataResultToApexChartSeries = (
  dailyLoadResult?: IRecognitionDailyLoad[] | IServerUnavailabilityByDay[]
): number[] => {
  if (!dailyLoadResult) return []
  const result = dailyLoadResult
    .map((day) => {
      if (day.dayIndex === 1) return {...day, dayIndex: 6}
      return {...day, dayIndex: day.dayIndex - 1}
    })
    .sort((a, b) => a.dayIndex - b.dayIndex)
    .map((day) => day.count)

  return result
}

export const getHoursFromHourlyCountsResult = (
  hourlyLoad?: IRecognitionHourlyLoad[] | IServerUnavailabilityByHour[]
): string[] => {
  if (!hourlyLoad) return []
  const result = hourlyLoad.map((day) => day.hour.toString())

  return result
}

export const parseHourlyCountsDataResultToApexChartSeries = (
  hourlyLoad?: IRecognitionHourlyLoad[] | IServerUnavailabilityByHour[]
): number[] => {
  if (!hourlyLoad) return []
  const result = hourlyLoad.map((day) => day.count)

  return result
}
