import React from 'react'
import {useDispatch} from 'react-redux'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {RootDispatcher} from 'app/store/root-dispatcher'
import {useHistory, useParams} from 'react-router'
import {authService} from 'app/services/auth.service'
import {LoaderButton} from 'app/components/buttons/loader-button/LoaderButton'
import {useToasts} from 'react-toast-notifications'
import {AppExceptionError} from '../../interfaces/error.interface'
import {useIntl} from 'react-intl'
import {useMutation, useQuery} from 'react-query'

interface IChangePassword {
  newPassword: string
  newPasswordConfirmed: string
}

const resetPasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .min(3, 'MIN_IS_3')
    .max(50, 'MAX_IS_50')
    .required('NEW_PASSWORD_IS_REQUIRED'),
  newPasswordConfirmed: Yup.string()
    .required('NEW_PASSWORD_CONFIRMATION_IS_REQUIREDd')
    .when('newPassword', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref('newPassword')],
        'PASSWORDS_MUST_MATCH'
      ),
    }),
})

export const ResetPassword = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const rootDispatcher = new RootDispatcher(dispatch)
  const history = useHistory()
  const {token}: {token: string} = useParams()
  const {addToast} = useToasts()

  useQuery(`get-password-token`, () => authService.getPasswordToken(token), {
    onSuccess: (tokenResponse) => {
      console.log('exists')
      if (!tokenResponse.data) {
        addToast(
          intl.formatMessage({
            id: `YOUR_TOKEN_HAS_EXPIRED_PLEASE_SEND_ANOTHER_REQUEST`,
          }),
          {
            appearance: 'warning',
          }
        )
        history.push('/auth/forgot-password')
      }
    },
  })

  const {mutate: changePassword, isLoading} = useMutation(
    async (passwordUpdateData: IChangePassword) => {
      const res = await authService.changeForgottenPassword({
        ...passwordUpdateData,
        token,
      })
      return res
    },
    {
      onSuccess: () => {
        rootDispatcher.setUser(null, false)
        localStorage.removeItem('accessToken')
        addToast(
          intl.formatMessage({
            id: `YOUR_PASSWORD_HAS_BEEN_CHANGED`,
          }),
          {appearance: 'success'}
        )
        history.push('/')
      },
      onError: (err: any) => {
        const {response} = err as AppExceptionError
        addToast(response.data.message, {appearance: 'error'})
      },
    }
  )

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      newPasswordConfirmed: '',
    },
    validationSchema: resetPasswordSchema,
    onSubmit: async (values) => {
      if (formik.isValid) changePassword(values)
    },
  })

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      onSubmit={formik.handleSubmit}
    >
      <div className='mb-10 text-center'>
        <h1 className='text-dark mb-3'>
          {intl.formatMessage({
            id: `RESET_PASSWORD`,
          })}
        </h1>
      </div>

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      <div className='mb-10 fv-row' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>
            {intl.formatMessage({
              id: `NEW_PASSWORD`,
            })}
          </label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              autoComplete='off'
              {...formik.getFieldProps('newPassword')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid':
                    formik.touched.newPassword && formik.errors.newPassword,
                },
                {
                  'is-valid':
                    formik.touched.newPassword && !formik.errors.newPassword,
                }
              )}
            />
            {formik.touched.newPassword && formik.errors.newPassword && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>
                    {intl.formatMessage({
                      id: formik.errors.newPassword,
                    })}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className='fv-row mb-5'>
        <label className='form-label fw-bolder text-dark fs-6'>
          {intl.formatMessage({
            id: `CONFIRM_PASSWORD`,
          })}
        </label>
        <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('newPasswordConfirmed')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid':
                formik.touched.newPasswordConfirmed &&
                formik.errors.newPasswordConfirmed,
            },
            {
              'is-valid':
                formik.touched.newPasswordConfirmed &&
                !formik.errors.newPasswordConfirmed,
            }
          )}
        />
        {formik.touched.newPasswordConfirmed &&
          formik.errors.newPasswordConfirmed && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>
                  {intl.formatMessage({
                    id: formik.errors.newPasswordConfirmed,
                  })}
                </span>
              </div>
            </div>
          )}
      </div>

      <div className='text-center'>
        <LoaderButton
          label={intl.formatMessage({
            id: `SUBMIT`,
          })}
          buttonType='submit'
          disabled={formik.isSubmitting || !formik.isValid}
          loading={isLoading}
          classes='btn-lg btn-primary w-100 mb-5'
        />

        <Link to='/auth/login'>
          <button
            type='button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
          >
            {intl.formatMessage({
              id: `CANCEL`,
            })}
          </button>
        </Link>
      </div>
    </form>
  )
}
