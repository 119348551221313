import CustomDateRangePicker from 'app/components/date-picker/CustomDateRangePicker'
import {PageTemplate} from 'app/components/page-template/PageTemplate'
import {Language} from 'app/enums/language.enum'
import dayjs from 'dayjs'
import React, {useState} from 'react'
import {Col, Row} from 'react-bootstrap-v5'
import {useIntl} from 'react-intl'
import {PageTitle} from '_metronic/layout/core'
import StatisticsMenuWrapper from '../components/StatisticsMenuWrapper'
import StatisticsMenuAdminBody from '../components/StatisticsMenuAdminBody'
import {serverStatisticsService} from 'app/services/server-statistics.service'
import {useQuery} from 'react-query'
import StatisticsCard from '../components/StatisticsCard'
import ServerUnavailabilityByHoursChart from './charts/ServerUnavailabilityByHoursChart'
import StatisticsCardValue from '../components/StatisticsCardValue'
import LoadingSpinner from 'app/components/loading-spinner/LoadingSpinner'
import {StatisticsMenuTitleEnum} from '../components/StatisticsMenuTitleEnum'
import {IconStatisticCard} from '../components/IconStatisticCard'

const ServerStatistics = () => {
  const intl = useIntl()
  const language = localStorage.getItem('lang') as Language
  const [dateFrom, setDateFrom] = useState(dayjs().startOf('month').toDate())
  const [dateTo, setDateTo] = useState(dayjs().toDate())

  const statistics = useQuery(
    ['get-server-statistics', dateTo],
    () =>
      serverStatisticsService.getServerStatistics({
        dateFrom,
        dateTo,
      }),
    {refetchOnWindowFocus: false, enabled: !!dateTo}
  )

  const handleDateChange = (dates: [Date, Date]) => {
    const [start, end] = dates
    setDateFrom(start)
    setDateTo(end)
  }

  return (
    <PageTemplate>
      {statistics.isFetching && <LoadingSpinner />}
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({id: 'SERVER_STATISTICS'})}
      </PageTitle>
      <Row>
        <Col md={4} className='p-2'>
          <StatisticsMenuWrapper title={StatisticsMenuTitleEnum.SERVER}>
            <StatisticsMenuAdminBody />
          </StatisticsMenuWrapper>
        </Col>
        <Col md={8} className='p-2 h-100'>
          <div className='card p-2 mb-2 d-flex align-items-end'>
            <CustomDateRangePicker
              selectsRange={true}
              startDate={dateFrom}
              endDate={dateTo}
              onChange={handleDateChange}
              dateFormat='dd.MM.yyyy'
              locale={language || 'en'}
            />
          </div>
          <div className='mb-2'>
            <StatisticsCard
              title={intl.formatMessage({id: 'SERVER_AVAILABILITY'})}
              icon={IconStatisticCard.Server}
            >
              <StatisticsCardValue
                value={
                  statistics.data
                    ? `${statistics.data.data.serverAvailabilityRatio.toFixed(
                        1
                      )}%`
                    : '-'
                }
              />
            </StatisticsCard>
          </div>

          <StatisticsCard
            title={intl.formatMessage({id: 'SERVER_UNAVAILABILITY_PER_HOUR'})}
            icon={IconStatisticCard.Server}
          >
            <ServerUnavailabilityByHoursChart
              serverUnavailabilityByHour={
                statistics.data?.data.unavailabilityByHour
              }
            />
          </StatisticsCard>
        </Col>
      </Row>
      <Row></Row>
    </PageTemplate>
  )
}

export default ServerStatistics
