import {ITokenTwoFactorEmail} from '../../interfaces/ITokenTwoFactor'
import createResetTwoFactorAuthentication from 'app/controllers/createResetTwoFactor'
import {useState} from 'react'
import TwoFactorEmailForm from '../../forms/twoFactorEmailForm'
import ResetTwoFactorMessage from './resetTwoFactorMessage'
import {useToasts} from 'react-toast-notifications'
import {useIntl} from 'react-intl'

interface ICreateResetTwoFactor {
  setIsLoading: (value: React.SetStateAction<boolean>) => void
  accessToken: string | null
  isLoading: boolean
  logoutUserHandler(): void
  setShowButtonBackOrReset: React.Dispatch<React.SetStateAction<boolean>>
}

function CreateResetTwoFactor({
  setIsLoading,
  accessToken,
  isLoading,
  logoutUserHandler,
  setShowButtonBackOrReset,
}: ICreateResetTwoFactor) {
  const intl = useIntl()
  const [showForm, setShowForm] = useState<boolean>(true)
  const {addToast} = useToasts()

  function emailHandleSubmit(
    values: ITokenTwoFactorEmail,
    {
      setErrors,
    }: {
      setErrors: Function
    }
  ) {
    setIsLoading(true)
    if (accessToken === null) {
      setErrors({token: intl.formatMessage({id: 'INCORRECT_CREDENTIALS'})})
      addToast(intl.formatMessage({id: 'INCORRECT_CREDENTIALS'}), {
        appearance: 'error',
      })
      setIsLoading(false)
      return
    }
    createResetTwoFactorAuthentication(values.email, accessToken)
      .then((data) => {
        setIsLoading(false)
        const {createResetTwoFactor} = data
        if (createResetTwoFactor) {
          setShowButtonBackOrReset(() => false)
          setShowForm((prev) => !prev)
        }
      })
      .catch((error) => {
        setIsLoading(false)
        addToast(intl.formatMessage({id: error.message}), {appearance: 'error'})
        setErrors(intl.formatMessage({id: error.message}))
      })
  }
  return (
    <>
      {showForm && (
        <TwoFactorEmailForm
          emailHandleSubmit={emailHandleSubmit}
          logoutUserHandler={logoutUserHandler}
          isLoading={isLoading}
        />
      )}
      {!showForm && <ResetTwoFactorMessage typeMessage='create' />}
    </>
  )
}

export default CreateResetTwoFactor
