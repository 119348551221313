import React from 'react'
import KidihubFooter from './components/KidihubFooter'
import KidihubLogo from './components/KidihubLogo'
import {
  KidihubPrivacyPolicyWrapper,
  SurveyContainer,
  SurveyH1,
  SurveyH3,
} from './Survey.styles'

const KidihubPrivacyPolicy = () => {
  return (
    <div>
      <KidihubLogo />
      <SurveyContainer>
        <KidihubPrivacyPolicyWrapper>
          <h1>Polityka prywatności strony internetowej Kidihub</h1>
          <p>
            Dbamy o Twoją prywatność i chcemy, zapewnić Ci komfort w czasie
            korzystania z naszych usług. Poniżej prezentujemy Ci najważniejsze
            informacje o zasadach przetwarzania przez nas Twoich danych
            osobowych oraz plikach cookies, które są wykorzystywane przez
            KIDIHUB. Informacje te zostały przygotowane z uwzględnieniem RODO,
            czyli ogólnego rozporządzenia o ochronie danych.
          </p>
          <h3>ADMINISTRATOR DANYCH OSOBOWYCH</h3>
          <p>BAKERS Sp. z o.o.</p>
          <p>ul. Branickiego 11, m.154</p>
          <p>02-972 Warszawa</p>
          <p>NIP: 9512420313</p>
          <p>KRS: 0000640282</p>
          <p>REGON: 365556022</p>
          <h3>DANE OSOBOWE I PRYWATNOŚĆ</h3>
          <p>
            Jeśli zamierzasz założyć konto Użytkownika oraz korzystać z naszych
            usług, poprosimy Cię o podanie nam Twoich danych osobowych. Twoje
            dane przetwarzane są przez nas we wskazanych poniżej celach,
            związanych z funkcjonowaniem strony internetowej KIDIHUB i
            świadczeniem usług oferowanych za jej pośrednictwem („Usługi”).
            Usługi te mogą polegać m.in. na prowadzeniu Twojego konta.
          </p>
          <h5>Cel przetwarzania:</h5>
          <p>W zależności od tego, na co się zdecydujesz, może to być:</p>
          <ul>
            <li>
              świadczenie usług oferowanych na stronie internetowej KIDIHUB, np.
              usługi prowadzenia konta,
            </li>
            <li>realizacja Twoich zamówień,</li>
            <li>
              marketing bezpośredni, mogący polegać na przesyłaniu lub
              wyświetlaniu spersonalizowanych reklam,
            </li>
            <li>przesyłanie newslettera.</li>
          </ul>
          <h5>Podstawa przetwarzania danych:</h5>
          <p>
            W zależności od podejmowanych przez Ciebie czynności, mogą to być:
          </p>
          <ul>
            <li>
              umowa o świadczenie usług lub działania podejmowane na Twoje
              żądanie, zmierzające do jej zawarcia (art. 6 ust 1 lit. b RODO)
            </li>
            <li>
              umowa sprzedaży lub działania podejmowane na Twoje żądanie,
              zmierzające do jej zawarcia (art. 6 ust. 1 lit. b RODO)
            </li>
            <li>
              ciążący na nas obowiązek prawny, przede wszystkim związany z
              rachunkowością (art. 6 ust. 1 lit. c RODO)
            </li>
            <li>
              Twoja zgoda wyrażona na stronie internetowej KIDIHUB – jeśli
              zdecydujesz się na jej wyrażenie (art. 6 ust. 1 lit. a RODO)
            </li>
            <li>
              nasz prawnie uzasadniony interes, polegający na przetwarzaniu
              danych w celu ustalenia, dochodzenia lub obrony ewentualnych
              roszczeń (art. 6 ust. 1 lit. f RODO)
            </li>
            <li>
              nasz prawnie uzasadniony interes, polegający na prowadzeniu
              marketingu bezpośredniego (art. 6 ust. 1 lit. f RODO).
            </li>
          </ul>
          <p>
            Podanie danych jest dobrowolne, ale w niektórych przypadkach może
            być konieczne do zawarcia umowy
          </p>
          <strong>
            <p>Skutek niepodania danych:</p>
            <p>W zależności od celu, w jakim dane są podawane:</p>
            <ul>
              <li>
                brak możliwości zarejestrowania się na stronie internetowej
                KIDIHUB
              </li>
              <li>
                brak możliwości korzystania z usług oferowanych na stronie
                internetowej KIDIHUB
              </li>
              <li>
                brak możliwości dokonania zakupów na stronie internetowej
                KIDIHUB
              </li>
              <li>
                brak możliwości otrzymywania informacji o promocjach czy
                ofertach specjalnych oferowanych na stronie internetowej KIDIHUB
              </li>
            </ul>
            <p>Możliwość cofnięcia zgody:</p>
          </strong>
          <ul>
            <li>zgodę możesz cofnąć w każdej chwili</li>
          </ul>
          <p>
            Przetwarzanie danych do momentu cofnięcia przez Ciebie zgody
            pozostaje zgodne z prawem.
          </p>

          <h3>PROFILOWANIE</h3>
          <p>
            W ramach sekcji “Sklep” możemy automatycznie dopasowywać pewne
            treści do Twoich potrzeb, tj. dokonywać profilowania, wykorzystując
            do tego Twoje dane osobowe. Profilowanie to polega przede wszystkim
            na automatycznej ocenie, jakie produkty mogą Cię najbardziej
            interesować, a dokonywane jest na podstawie Twoich dotychczasowych
            działań podejmowanych w Internecie, w tym w ramach stron
            internetowych Administratora, i na przesyłaniu lub wyświetlaniu
            personalizowanych w ten sposób reklam produktów. Profilowanie,
            którego dokonujemy nie skutkuje podejmowaniem decyzji wywołujących
            wobec Ciebie skutki prawne lub wpływających na Ciebie w podobnie
            istotny sposób.
          </p>

          <h3>OKRES PRZETWARZANIA</h3>
          <p>
            Twoje dane będziemy przetwarzać tylko przez okres, w którym będziemy
            mieć ku temu podstawę prawną, a więc do momentu, w którym:
          </p>
          <ul>
            <li>
              przestanie ciążyć na nas obowiązek prawny, zobowiązujący nas do
              przetwarzania Twoich danych (zwłaszcza związany z rachunkowością)
              lub
            </li>
            <li>
              ustanie możliwość ustalania, dochodzenia lub obrony roszczeń
              związanych z umową zawartą przez BAKERS Sp. z o.o.przez Strony lub
            </li>
            <li>
              kiedy cofniesz zgodę na przetwarzanie danych, jeśli to ona była
              jego podstawą.
            </li>
            <li>
              w zależności od tego, co ma zastosowanie w danym przypadku i co
              nastąpi najpóźniej.
            </li>
          </ul>

          <h3>BEZPIECZEŃSTWO DANYCH</h3>
          <p>
            Przetwarzając Twoje dane osobowe stosujemy środki organizacyjne i
            techniczne zgodne z właściwymi przepisami prawa, w tym stosujemy
            szyfrowanie połączenia za pomocą certyfikatu SSL
          </p>

          <h3>TWOJE UPRAWNIENIA</h3>
          <p>Ponadto przysługuje Ci prawo żądania:</p>
          <ul>
            <li>dostępu do Twoich danych osobowych</li>
            <li>sprostowania tych danych</li>
            <li>usunięcia ich</li>
            <li>ograniczenia ich przetwarzania</li>
            <li>żądania przeniesienia danych do innego administratora.</li>
          </ul>
          <p>
            A także prawo wniesienia w dowolnym momencie sprzeciwu wobec
            przetwarzania Twoich danych:
          </p>
          <ul>
            <li>
              z przyczyn związanych z Twoją szczególną sytuacją – wobec
              przetwarzania dotyczących Ciebie danych osobowych, opartego na
              art. 6 ust. 1 lit. f RODO (tj. na prawnieuzasadnionych interesach
              realizowanych przez administratora), w tym profilowania na
              podstawie tych przepisów,
            </li>
            <li>
              jeżeli dane osobowe są przetwarzane na potrzeby marketingu
              bezpośredniego, w tym profilowania, w zakresie, w jakim
              przetwarzanie jest związane z takim marketingiem bezpośrednim.
            </li>
          </ul>
          <p>
            Skontaktuj się z nami, jeśli chcesz skorzystać ze swoich praw. Jeśli
            uznasz, że Twoje dane są przetwarzane niezgodnie z prawem, możesz
            złożyć skargę do organu nadzorczego.
          </p>

          <h3>CIASTECZKA</h3>
          <p>
            KIDIHUB, jak większość witryn internetowych, korzysta z tzw. plików
            cookies (ciasteczek). Pliki te:
          </p>
          <ul>
            <li>
              są zapisywane w pamięci Twojego urządzenia (komputera, telefonu,
              itd.);
            </li>
            <li>
              umożliwiają Ci, m.in., korzystanie ze wszystkich funkcji strony
              internetowej KIDIHUB;
            </li>
            <li>nie powodują zmian w ustawieniach Twojego urządzenia.</li>
          </ul>
          <p>
            Korzystając z odpowiednich opcji Twojej przeglądarki, w każdej
            chwili możesz:
          </p>
          <ul>
            <li>usunąć pliki cookies,</li>
            <li>blokować wykorzystanie plików cookies w przyszłości.</li>
          </ul>
          <p>W KIDIHUB ciasteczka wykorzystywane są w celach:</p>
          <ul>
            <li>zapamiętywania informacji o Twojej sesji,</li>
            <li>statystycznych,</li>
            <li>marketingowych,</li>
            <li>udostępniania funkcji strony internetowej KIDIHUB</li>
          </ul>
          <p>
            Aby dowiedzieć się, jak zarządzać plikami cookies, w tym jak
            wyłączyć ich obsługę w Twojej przeglądarce, możesz skorzystać z
            pliku pomocy Twojej przeglądarki. Z informacjami na ten temat możesz
            zapoznać się wciskając klawisz F1 w przeglądarce. Ponadto
            odpowiednie wskazówki znajdziesz na następujących podstronach, w
            zależności od przeglądarki, której używasz:
          </p>
          <ul>
            <li>Firefox</li>
            <li>Chrome</li>
            <li>Safari</li>
            <li>Internet Explorer / Microsoft Edge</li>
          </ul>
          <p>Więcej informacji o ciasteczkach znajdziesz w Wikipedii.</p>

          <h3>USŁUGI ZEWNĘTRZNE / ODBIORCY DANYCH</h3>
          <p>
            W związku ze stroną internetową KIDIHUB korzystamy z usług podmiotów
            zewnętrznych, którym mogą być przekazywane Twoje dane (wyłącznie w
            celach wskazanych w niniejszej polityce).
          </p>
          <p>Poniżej znajduje się lista możliwych odbiorców Twoich danych:</p>
          <ul>
            <li>Podmiot realizujący dostawę towarów</li>
            <li>Dostawca płatności</li>
            <li>
              Osoby współpracujące z nami na podstawie umów cywilnoprawnych,
              wspierające naszą bieżącą działalność
            </li>
            <li>
              Dostawca oprogramowania potrzebnego do prowadzenia sklepu
              internetowego
            </li>
            <li>Dostawca oprogramowania ERP</li>
            <li>Podmiot prowadzący punkt odbioru paczek</li>
            <li>Podmiot zapewniający system mailingowy</li>
            <li>Podmiot zapewniający usługi marketingowe</li>
            <li>
              Podmiot zapewniający obsługę zamówień, w tym zwrotów i reklamacji
            </li>
            <li>
              Podmiot ułatwiający optymalizację strony internetowej KIDIHUB
            </li>
            <li>Portale społecznościowe</li>
            <li>Podmiot zapewniający nam wsparcie techniczne</li>
            <li>
              Odpowiednie organy publiczne w zakresie, w jakim Administrator
              jest zobowiązany do udostępnienia im danych
            </li>
          </ul>

          <h3>
            PRZEKAZYWANIE DANYCH OSOBOWYCH DO PAŃSTW SPOZA UNII EUROPEJSKIEJ
          </h3>
          <p>
            W związku z powyższym, Twoje dane osobowe mogą być przetwarzane
            również przez podmioty spoza Unii Europejskiej. Właściwy poziom
            ochrony Twoich danych, w tym poprzez stosowanie odpowiednich
            zabezpieczeń, zapewnia uczestnictwo tych podmiotów w Tarczy
            Prywatności UE-USA, ustanowionej decyzją wykonawczą Komisji
            Europejskiej jako zbiór zasad gwarantujących odpowiednią ochronę
            Twojej prywatności.
          </p>

          <h3>KONTAKT Z ADMINISTRATOREM</h3>
          <p>
            Chcesz skorzystać ze swoich uprawnień dotyczących danych osobowych?
          </p>
          <p>
            A może po prostu chcesz zapytać o coś związanego z naszą Polityką
            Prywatności?
          </p>
          <p>Napisz na adres e-mail: kontakt@kidihub.com</p>
        </KidihubPrivacyPolicyWrapper>
      </SurveyContainer>
      <KidihubFooter />
    </div>
  )
}

export default KidihubPrivacyPolicy
