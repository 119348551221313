import React, {FC} from 'react'
import {Card} from 'react-bootstrap-v5'
import {useIntl} from 'react-intl'
import styled from 'styled-components'
import {StatisticsMenuTitleEnum} from './StatisticsMenuTitleEnum'

const MenuBackground = styled.div`
  position: relative;
  height: 200px;
  img {
    width: 100%;
    position: absolute;
    top: 60px;
  }
`
interface IStatisticsMenuWrapper {
  title: StatisticsMenuTitleEnum
  children: React.ReactNode | React.ReactNode[]
}

const StatisticsMenuWrapper: FC<IStatisticsMenuWrapper> = ({
  title,
  children,
}) => {
  const intl = useIntl()

  return (
    <Card className='position-relative h-100' style={{zIndex: 10}}>
      <MenuBackground
        className='position-absolute w-100 rounded-2'
        style={{zIndex: -1, backgroundColor: `#F64E60`}}
      >
        <img
          src='/images/statistics-menu-wave.svg'
          style={{
            color: 'black',
            top: '50px',
          }}
          alt='wave'
        />
        <img
          src='/images/statistics-menu-wave.svg'
          style={{
            color: 'black',
            opacity: '0.3',
            top: '58px',
          }}
          alt='wave'
        />
      </MenuBackground>
      <Card.Header className='border-0 pt-5 pb-20 rounded-2'>
        <Card.Title className='text-white'>
          {intl.formatMessage({id: `${title}`})}
        </Card.Title>
      </Card.Header>
      <Card.Body>{children}</Card.Body>
    </Card>
  )
}

export default StatisticsMenuWrapper
