import {Form, Formik, FormikProps, FieldProps, Field} from 'formik'
import * as Yup from 'yup'
import {LoaderButton} from 'app/components/buttons/loader-button/LoaderButton'
import {ITokenTwoFactorEmailAndPin} from '../interfaces/ITokenTwoFactor'
import {useIntl} from 'react-intl'

interface ITwoFactiorForm {
  confirmResetTwoFactornHandleSubmit(
    values: ITokenTwoFactorEmailAndPin,
    {setErrors}: {setErrors: Function}
  ): void
  isLoading: boolean
}

function TwoFactorEmailAndPinForm({
  confirmResetTwoFactornHandleSubmit,
  isLoading,
}: ITwoFactiorForm) {
  const initialValues = {email: '', pin: ``}
  const intl = useIntl()
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(`INCORRECT_EMAIL`)
      .min(3, `MIN_IS_3`)
      .max(50, `MAX_IS_50`)
      .required(`EMAIL_IS_REQUIRED`),
    pin: Yup.string()
      .matches(/^[0-9]{4}$/, 'MUST_BE_EXACTLY_4_DIGITS')
      .required(`PIN_IS_REQURIED`),
  })
  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={confirmResetTwoFactornHandleSubmit}
      >
        {(formik: FormikProps<ITokenTwoFactorEmailAndPin>) => (
          <Form className='' noValidate autoComplete='off'>
            <Field name='email'>
              {({
                field,
                form,
                meta,
              }: FieldProps<ITokenTwoFactorEmailAndPin>) => {
                return (
                  <div className='my-2'>
                    <label className='d-block form-label fs-6 fw-bolder text-dark mt-4'>
                      Email:
                    </label>
                    <input
                      type='text'
                      id='email'
                      className={`form-control form-control-lg form-control-solid     `}
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      onBlur={formik.handleBlur}
                    />
                    <span
                      className={`d-block text-danger my-0 text-center ${
                        formik.touched.email && formik.errors.email
                          ? `my-1`
                          : `my-9`
                      }`}
                    >
                      {formik.touched.email &&
                        formik.errors.email &&
                        intl.formatMessage({
                          id: formik.errors.email,
                        })}
                    </span>
                  </div>
                )
              }}
            </Field>
            <Field name='pin'>
              {({meta}: FieldProps<ITokenTwoFactorEmailAndPin>) => {
                return (
                  <div className='my-2'>
                    <label className='form-label fs-6 fw-bolder text-dark'>
                      PIN:
                    </label>
                    <input
                      type='text'
                      id='pin'
                      className={`form-control form-control-lg form-control-solid`}
                      onChange={formik.handleChange}
                      value={formik.values.pin}
                      onBlur={formik.handleBlur}
                    />
                    <span
                      className={`d-block text-danger my-0 text-center ${
                        formik.touched.pin && meta.error ? `my-1` : `my-12`
                      }`}
                    >
                      {formik.touched.pin &&
                        meta.error &&
                        intl.formatMessage({
                          id: meta.error,
                        })}
                    </span>
                  </div>
                )
              }}
            </Field>
            <div className='my-5'>
              <LoaderButton
                label='Continue'
                buttonType='submit'
                disabled={!!(formik.errors.email || formik.errors.pin)}
                classes={`btn-lg btn-primary w-100 mt-1`}
                loading={isLoading}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default TwoFactorEmailAndPinForm
