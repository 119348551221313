import React, {FC} from 'react'

interface IProps {
  value: string
  isAverage?: boolean
}

const StatisticsCardValue: FC<IProps> = ({value, isAverage}) => {
  return (
    <h1 className={`fw-bolder`}>
      {value} <span className='text-muted fs-7'>{isAverage && 'avg'}</span>
    </h1>
  )
}

export default StatisticsCardValue
