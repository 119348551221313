import {Language} from 'app/enums/language.enum'
import {LatestNotifications} from 'app/interfaces/notification.interface'
import {Action, Reducer} from 'redux'
import {UserPartialNoTokens} from '../interfaces/user.interface'

export interface InitialState {
  language: Language | null
  user: UserPartialNoTokens
  confirmationModal: boolean
  notifications: LatestNotifications | null
}

export const initialState: InitialState = {
  user: undefined,
  language: Language.ENGLISH,
  confirmationModal: false,
  notifications: null,
}

export enum ActionType {
  SET_USER = 'SET_USER',
  SET_LANGUAGE = 'SET_LANGUAGE',
  SET_CONFIRMATION_MODAL = 'SET_CONFIRMATION_MODAL',
  SET_NOTIFICATIONS = 'SET_NOTIFICTIONS',
  SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN',
  SET_IS_USE_2FA = 'SET_IS_USE_2FA',
  SET_IS_NEW_USER = 'SET_IS_NEW_USER',
}

export interface DispatchAction extends Action<ActionType> {
  payload: Partial<InitialState>
}

export const rootReducer: Reducer<InitialState, DispatchAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ActionType.SET_USER:
      return {...state, ...action.payload}
    case ActionType.SET_CONFIRMATION_MODAL:
      return {...state, ...action.payload}
    case ActionType.SET_LANGUAGE:
      return {...state, ...action.payload}
    case ActionType.SET_NOTIFICATIONS:
      return {...state, ...action.payload}

    case ActionType.SET_IS_USE_2FA:
      if (!action.payload?.user?.isUsedTwoFactor || !state.user) {
        return {...state}
      }
      return {
        ...state,
        user: {
          ...state.user,
          isUsedTwoFactor: action.payload.user.isUsedTwoFactor,
        },
      }
    case ActionType.SET_IS_NEW_USER:
      if (!state.user || action.payload?.user?.isNewUser === undefined) {
        return {...state}
      }
      return {
        ...state,
        user: {
          ...state.user,
          isNewUser: action.payload.user.isNewUser,
        },
      }
    default:
      return state
  }
}
