import React from 'react'
import {Container} from 'react-bootstrap-v5'
import {useIntl} from 'react-intl'

const PrivacyPolicyPage = () => {
  return (
    <Container>
      <div>
        <h3 className='mt-5 text-center'>
          Polityka prywatności platformy internetowej{' '}
          <a href='https://console.suuol.com/'>SUUOL Console</a>
        </h3>
        <p>
          Dbamy o Twoją prywatność i chcemy zapewnić Ci komfort w czasie
          korzystania z naszych usług. Poniżej prezentujemy Ci najważniejsze
          informacje o zasadach przetwarzania przez nas Twoich danych osobowych
          oraz plikach cookies, które są wykorzystywane przez SUUOL Console.
          Informacje te zostały przygotowane z uwzględnieniem RODO, czyli
          ogólnego rozporządzenia o ochronie danych.
        </p>
        <p>
          <strong>ADMINISTRATOR DANYCH OSOBOWYCH</strong>
          <br />
          BAKERS Sp. z o.o. <br />
          ul. Branickiego 11, m.154 <br />
          02-972 Warszawa <br />
          NIP: 9512420313 <br />
          KRS: 0000640282 <br />
          REGON: 365556022 <br />
        </p>
        <p>
          <strong>DANE OSOBOWE I PRYWATNOŚĆ</strong>
          <br />
          Jeśli zamierzasz założyć konto Użytkownika oraz korzystać z naszych
          usług, poprosimy Cię o podanie nam Twoich danych osobowych. Twoje dane
          przetwarzane są przez nas we wskazanych poniżej celach, związanych z
          funkcjonowaniem platformy internetowej SUUOL Console i świadczeniem
          usług oferowanych za jej pośrednictwem („Usługi”). Usługi te mogą
          polegać m.in. na prowadzeniu Twojego konta.
        </p>
        <p>
          <strong>Cel przetwarzania:</strong> świadczenie usług oferowanych za
          pośrednictwem platformy internetowej SUUOL Console, w tym usługa
          prowadzenia konta
        </p>
        <p>
          <strong>Podstawa przetwarzania danych:</strong>
          <br />W zależności od podejmowanych przez Ciebie czynności, mogą to
          być:
          <ul>
            <li>
              umowa o świadczenie usług lub działania podejmowane na Twoje
              żądanie, zmierzające do jej zawarcia (art. 6 ust 1 lit. b RODO)
            </li>
            <li>
              ciążący na nas obowiązek prawny, przede wszystkim związany z
              rachunkowością (art. 6 ust. 1 lit. c RODO) Twoja zgoda wyrażona na
              stronie internetowej SUUOL Console – jeśli zdecydujesz się na jej
              wyrażenie (art. 6 ust. 1 lit. a RODO)
            </li>
            <li>
              nasz prawnie uzasadniony interes, polegający na przetwarzaniu
              danych w celu ustalenia, dochodzenia lub obrony ewentualnych
              roszczeń (art. 6 ust. 1 lit. f RODO)
            </li>
          </ul>
        </p>
        <p>
          <strong>
            Podanie danych jest dobrowolne, ale w niektórych przypadkach może
            być konieczne do zawarcia umowy.
          </strong>
        </p>
        <p>
          <strong>Skutek niepodania danych:</strong> brak możliwości
          zarejestrowania się na platformie internetowej SUUOL Console oraz brak
          możliwości korzystania z usług oferowanych za jej pośrednictwem.
        </p>
        <p>
          <strong>Możliwość cofnięcia zgody:</strong> zgodę możesz cofnąć w
          każdej chwili. Przetwarzanie danych do momentu cofnięcia przez Ciebie
          zgody pozostaje zgodne z prawem.
        </p>
        <p>
          <strong>OKRES PRZETWARZANIA</strong>
          <br />
          Twoje dane będziemy przetwarzać tylko przez okres, w którym będziemy
          mieć ku temu podstawę prawną, a więc do momentu, w którym:
          <ul>
            <li>
              przestanie ciążyć na nas obowiązek prawny, zobowiązujący nas do
              przetwarzania Twoich danych lub
            </li>
            <li>
              ustanie możliwość ustalania, dochodzenia lub obrony roszczeń
              związanych z umową zawartą przez BAKERS Sp. z o.o.przez Strony lub
            </li>
            <li>
              kiedy cofniesz zgodę na przetwarzanie danych, jeśli to ona była
              jego podstawą.
            </li>
          </ul>
          - w zależności od tego, co ma zastosowanie w danym przypadku i co
          nastąpi najpóźniej.
        </p>
        <p>
          <strong>BEZPIECZEŃSTWO DANYCH</strong>
          <br />
          Przetwarzając Twoje dane osobowe stosujemy środki organizacyjne i
          techniczne zgodne z właściwymi przepisami prawa, w tym stosujemy
          szyfrowanie połączenia za pomocą certyfikatu SSL.
        </p>
        <p>
          <strong>TWOJE UPRAWNIENIA</strong>
          <br />
          Ponadto przysługuje Ci prawo żądania:
          <ul>
            <li>dostępu do Twoich danych osobowych</li>
            <li>sprostowania tych danych</li>
            <li>usunięcia ich</li>
            <li>ograniczenia ich przetwarzania</li>
          </ul>
          A także prawo wniesienia w dowolnym momencie sprzeciwu wobec
          przetwarzania Twoich danych:
          <ul>
            <li>
              z przyczyn związanych z Twoją szczególną sytuacją – wobec
              przetwarzania dotyczących Ciebie danych osobowych, opartego na
              art. 6 ust. 1 lit. f RODO (tj. na prawnie uzasadnionych interesach
              realizowanych przez administratora),{' '}
            </li>
          </ul>
          Skontaktuj się z nami, jeśli chcesz skorzystać ze swoich praw. Jeśli
          uznasz, że Twoje dane są przetwarzane niezgodnie z prawem, możesz
          złożyć skargę do organu nadzorczego.
        </p>
        <p>
          <strong>CIASTECZKA</strong>
          <br />
          SUUOL Console, jak większość witryn internetowych, korzysta z tzw.
          plików cookies (ciasteczek). Pliki te:
          <ul>
            <li>
              są zapisywane w pamięci Twojego urządzenia (komputera, telefonu,
              itd.);
            </li>
            <li>
              umożliwiają Ci, m.in., korzystanie ze wszystkich funkcji strony
              internetowej SUUOL Console;
            </li>
            <li>nie powodują zmian w ustawieniach Twojego urządzenia.</li>
          </ul>
          Korzystając z odpowiednich opcji Twojej przeglądarki, w każdej chwili
          możesz:
          <ul>
            <li>usunąć pliki cookies,</li>
            <li>blokować wykorzystanie plików cookies w przyszłości.</li>
          </ul>
          W SUUOL Console cookies wykorzystywane są w celach:
          <ul>
            <li>zapamiętywania informacji o Twojej sesji,</li>
            <li>statystycznych,</li>
            <li>udostępniania funkcji strony internetowej SUUOL Console</li>
          </ul>
          Aby dowiedzieć się, jak zarządzać plikami cookies, w tym jak wyłączyć
          ich obsługę w Twojej przeglądarce, możesz skorzystać z pliku pomocy
          Twojej przeglądarki. Z informacjami na ten temat możesz zapoznać się
          wciskając klawisz F1 w przeglądarce. Ponadto odpowiednie wskazówki
          znajdziesz na następujących podstronach, w zależności od przeglądarki,
          której używasz:
          <ul>
            <li>Firefox</li>
            <li>Chrome</li>
            <li>Safari</li>
            <li>Internet Explorer / Microsoft Edge</li>
          </ul>
          Więcej informacji o ciasteczkach znajdziesz w Wikipedii.
        </p>
        <p>
          <strong>USŁUGI ZEWNĘTRZNE / ODBIORCY DANYCH</strong>
          <br />W związku ze stroną internetową SUUOL Console korzystamy z usług
          podmiotów zewnętrznych, którym mogą być przekazywane Twoje dane
          (wyłącznie w celach wskazanych w niniejszej polityce). Poniżej
          znajduje się lista możliwych odbiorców Twoich danych:
          <ul>
            <li>
              Osoby współpracujące z nami na podstawie umów cywilnoprawnych,
              wspierające naszą bieżącą działalność
            </li>
            <li>
              Odpowiednie organy publiczne w zakresie, w jakim Administrator
              jest zobowiązany do udostępnienia im danych
            </li>
          </ul>
        </p>
        <p>
          <strong>
            PRZEKAZYWANIE DANYCH OSOBOWYCH DO PAŃSTW SPOZA UNII EUROPEJSKIEJ
          </strong>
          <br />
          Twoje dane osobowe nie są przekazywane podmiotom spoza Unii
          Europejskiej. Dlatego też zapisy RODO stanowią zbiór zasad
          gwarantujących odpowiednią ochronę Twojej prywatności. RODO nakłada na
          wszystkie firmy działające w UE (bez względu na miejsce ich siedziby)
          takie same zasady ochrony danych.
        </p>
        <p>
          <strong>KONTAKT Z ADMINISTRATOREM</strong>
          <br />
          Chcesz skorzystać ze swoich uprawnień dotyczących danych osobowych? A
          może po prostu chcesz zapytać o coś związanego z naszą Polityką
          Prywatności? Napisz na adres e-mail: kontakt@suuol.com
        </p>
      </div>
    </Container>
  )
}

export default PrivacyPolicyPage
