import {Button, Form} from 'react-bootstrap-v5'
import styled from 'styled-components'

// NOTE -------------
// TYMCZASOWE - usuniemy gdy ankieta kidihub się zakończy
export const LogoWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  min-height: 50px;
  max-height: 120px;
  text-align: center;
  img {
    height: 50px;
  }
`

export const KidihubButton = styled.button<{
  outlined?: boolean
  color?: string
}>`
  color: ${({outlined, color}) =>
    outlined ? (color ? color : 'black') : 'white'};
  background-color: ${({outlined, color}) =>
    outlined ? 'white' : color ? color : 'black'};
  border: 1px solid ${({color}) => color || 'black'};
  padding: 5px 20px;
  border-radius: 30px;
  display: block;
  margin: 3px auto;
`
export const UniaImageWrapper = styled.div`
  min-height: 50px;
  max-height: 100px;
  max-width: 540px;
  margin: 0 auto;
  img {
    max-height: 100px;
    width: 100%;
  }
`
export const KidihubPrivacyPolicyWrapper = styled.div`
  text-align: justify;
  h1,
  h3,
  h5,
  p,
  ul {
    color: ${({color}) => color || 'black'};
  }
  h1 {
    text-align: center;
  }
`
export const KidihubH4 = styled.h4``
export const PrivacyPolicyWrapper = styled.footer`
  font-size: 10px;
  color: ${({color}) => color || 'black'};
  text-align: center;
  margin-top: 20px;
`

// NOTE -------------
export const SurveyContainer = styled.div<{font?: string}>`
  font-family: ${({font}) => (font ? font : 'Helvetica')}, 'sans-serif';
  text-align: center;
  width: 90%;
  margin: 0 auto;
  margin-top: 20px;
  min-height: 60vh;
`
export const SurveyH1 = styled.h1<{color?: string}>`
  font-size: 32px;
  margin-bottom: 20px;
  color: ${({color}) => color || 'black'};
`
export const SurveyH3 = styled.h3`
  font-size: 22px;
  margin-bottom: 20px;
`
export const SurveyH4 = styled.h3`
  font-size: 20px;
  margin-bottom: 20px;
`
export const SurveyParagraph = styled.p`
  font-size: 16px;
  max-width: 540px;
  margin: 20px auto;
`
export const SurveyProductImage = styled.img`
  max-height: 400px;
`
export const SurveyIconButton = styled.button`
  width: 80px;
  height: 80px;
  border: 1px solid lightgray;
  background-color: transparent;
  border-radius: 50%;
  margin: 10px;
`
export const SurveyPriceSelect = styled(Form.Select)`
  max-width: 120px;
  margin: 5px auto 15px auto;
`
export const SurveyEmailInput = styled.div`
  max-width: 400px;
  margin: 5px auto;
`
