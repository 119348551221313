import {Redirect, Route, Switch} from 'react-router-dom'
import {lazy} from 'react'
import SurveyNotActive from 'app/pages/survey/components/SurveyNotActive'
import KidihubPrivacyPolicy from 'app/pages/survey/KidihubPrivacyPolicy'

function SurveyRoutes() {
  const SurveyInit = lazy(() => import('../pages/survey/SurveyInit'))
  const SurveyInfo = lazy(() => import('../pages/survey/SurveyInfo'))
  const Survey = lazy(() => import('../pages/survey/Survey'))
  const SurveySummary = lazy(() => import('../pages/survey/SurveySummary'))
  const SurveyConfirm = lazy(() => import('../pages/survey/SurveyConfirm'))
  return (
    <Switch>
      <Route exact path='/survey/not-active' component={SurveyNotActive} />
      <Route
        exact
        path='/survey/privacy-policy'
        component={KidihubPrivacyPolicy}
      />
      <Route exact path='/survey/:hash' component={SurveyInit} />
      <Route exact path='/survey/:hash/info' component={SurveyInfo} />
      <Route exact path='/survey/:hash/:surveySessionId' component={Survey} />
      <Route
        exact
        path='/survey/:hash/:surveySessionId/summary'
        component={SurveySummary}
      />
      <Route
        exact
        path='/survey/:hash/:surveySessionId/confirm'
        component={SurveyConfirm}
      />
      <Route exact path='/survey'>
        <Redirect to='/error/404' />
      </Route>
    </Switch>
  )
}

export default SurveyRoutes
