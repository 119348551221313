import {Redirect, Route, Switch} from 'react-router-dom'
import {MasterLayoutDoc} from '_metronic/layout/MasterLayoutDoc'
import {lazy} from 'react'
import {Content} from '_metronic/layout/components/Content'
import {Errors} from 'app/pages/errors/Errors'

function DocRoutes() {
  const DocumentationRecognitionRestApiPage = lazy(
    () => import('../pages/documentation/DocumentationRecognitionRestApiPage')
  )
  return (
    <MasterLayoutDoc>
      <div className='post d-flex flex-column-fluid' id='kt_post'>
        <Content>
          <Switch>
            <Route
              exact
              path='/doc/recognition/config/restapi'
              component={DocumentationRecognitionRestApiPage}
            />
            <Route path='/doc'>
              <Redirect to='/doc/recognition/config/restapi' />
            </Route>
            <Route path='/doc/*' component={Errors} />
          </Switch>
        </Content>
      </div>
    </MasterLayoutDoc>
  )
}

export default DocRoutes
