import {ChangePasswordPayload} from 'app/interfaces/auth.interface'
import {User} from 'app/interfaces/user.interface'
import axios, {AxiosResponse} from 'axios'
import {
  DisplayParams,
  SearchResult,
} from '../interfaces/search-result.interface'

interface IUsersDisplayParams extends DisplayParams {
  email?: string
}

const getUsers = (
  params: IUsersDisplayParams
): Promise<AxiosResponse<SearchResult<User>>> => {
  return axios.get('/api/users', {params})
}

const getUser = (id: string): Promise<AxiosResponse<User>> => {
  return axios.get(`/api/users/${id}`)
}

const getSelf = () => {
  return axios.get(`/api/users/self`)
}

const updateSelf = (payload: any) => {
  return axios.put(`/api/users/self`, payload)
}

const updateUserAccess = (id: string, payload: Partial<User>) => {
  return axios.put(`/api/users/${id}/access`, payload)
}

const updateUserPassword = (payload: ChangePasswordPayload) => {
  return axios.put('/api/users/change-password', payload)
}

const logoutServer = () => {
  return axios.post(`/api/auth/logout`)
}

export const userService = {
  getUsers,
  getUser,
  getSelf,
  updateSelf,
  updateUserPassword,
  updateUserAccess,
  logoutServer,
}
