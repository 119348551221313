import {useQuery} from 'react-query'
import createTwoFactorAuthentication from 'app/controllers/createTwoFactor'
import FadeLoader from 'react-spinners/FadeLoader'
import {useDispatch, useSelector} from 'react-redux'
import {RootDispatcher} from '../../store/root-dispatcher'
import {InitialState} from '../../store/root-reducer'
import {useState} from 'react'
import {useIntl} from 'react-intl'

function CodeQRCreateTwoFactor() {
  const dispatch = useDispatch()
  const rootDispatcher = new RootDispatcher(dispatch)
  const userState = useSelector((state: InitialState) => state.user)
  const intl = useIntl()
  const [isLoadingImage, setIsLoadingImage] = useState<boolean>(true)
  const {
    data,
    isError,
    isLoading: isLoadingData,
  } = useQuery(`createTwoFactor`, createTwoFactorAuthentication, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchIntervalInBackground: false,
    cacheTime: 1000,
  })
  // TODO: [KBCONSOLE-81] after create QR Code user should be logout or enter token from QR Code ?
  // function logoutByTwoFactorHandler() {
  //   rootDispatcher.setIsUseTwoFactor(null, false)
  //   localStorage.clear()
  // }
  // TODO: [KBCONSOLE-80] add skeletor ??
  function loginByTwoFactorHandler() {
    if (userState != null) {
      rootDispatcher.setIsUseTwoFactor(userState, true)
      rootDispatcher.setIsNewUser(userState, false)
    }
  }
  if (data?.accessToken) {
    localStorage.setItem(`accessToken`, data.accessToken)
  }

  return (
    <div className='d-flex flex-column'>
      <div
        className='d-flex flex-row justify-content-center m-3'
        style={{height: `200px`}}
      >
        {(isLoadingData || isLoadingImage) && (
          <div className='d-flex w-100 h-100 justify-content-center align-items-center'>
            <FadeLoader
              color={`#0d6efd`}
              loading={isLoadingData || isLoadingImage}
              height={15}
              width={5}
            />
          </div>
        )}
        {isError && (
          <div className='d-flex w-100 h-100 justify-content-center align-items-center'>
            <p className=''>{`Error create QR Code`}</p>
          </div>
        )}

        {data && (
          <div
            className={`d-flex ${
              isLoadingImage ? `w-0 h-0` : `w-100 h-100`
            }w-100 h-100 justify-content-center align-items-center`}
          >
            <img
              src={`${data.uri}`}
              alt='QR CODE'
              onLoad={() => {
                setIsLoadingImage(false)
              }}
            />
          </div>
        )}
      </div>
      <div className='d-flex flex-column py-5'>
        {/*
        // TODO: [KBCONSOLE-81] after create QR Code user should be logout or enter token from QR Code ?        
        <button onClick={logoutByTwoFactorHandler} className={`btn btn-lg btn-primary w-100 my-3`}>
          <span>{`Login`}</span>
        </button> */}
        <button
          onClick={loginByTwoFactorHandler}
          className={`btn btn-lg btn-primary w-100 my-3`}
        >
          <span>{`${intl.formatMessage({id: `LOGIN`})}`}</span>
        </button>
      </div>
    </div>
  )
}

export default CodeQRCreateTwoFactor
