import React from 'react'
import {useIntl} from 'react-intl'

interface Props {
  buttonType: 'button' | 'submit'
  disabled: boolean
  loading: boolean
  label: string
  classes: string
}

export const LoaderButton = (props: Props) => {
  const intl = useIntl()
  return (
    <button type={props.buttonType} className={`btn ${props.classes}`} disabled={props.disabled}>
      {!props.loading && <span className='indicator-label'>{props.label}</span>}
      {props.loading && (
        <span className='indicator-progress' style={{display: 'block'}}>
          {intl.formatMessage({id: 'PLEASE_WAIT'})}...
          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
        </span>
      )}
    </button>
  )
}
