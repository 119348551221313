import React, {useState} from 'react'
import {StepCreateTwoFactor} from './createTwoFactorStepper'
import {useIntl} from 'react-intl'

interface IWelcomTwoFactor {
  setSteps: React.Dispatch<React.SetStateAction<StepCreateTwoFactor>>
}

function BeforeCreateTwoFactor({setSteps}: IWelcomTwoFactor) {
  const [confirm, setConfirm] = useState<boolean>(false)
  const intl = useIntl()
  return (
    <div className='d-flex flex-column'>
      <div className='d-flex flex-row'>
        <p className=''>
          {intl.formatMessage({
            id: 'CREATE_TWO_FACTOR_AUTHENTICATION_STEP_TWO',
          })}
          <a
            href={`https://play.google.com/store/apps/details?id=com.azure.authenticator&hl=pl&gl=US`}
            target='_blank'
            rel='noopener noreferrer'
          >
            Google Play
          </a>
          {intl.formatMessage({
            id: 'OR',
          })}
          <a
            href={`https://apps.apple.com/us/app/microsoft-authenticator/id983156458`}
            target='_blank'
            rel='noopener noreferrer'
          >
            App Store
          </a>
        </p>
      </div>
      <div className='d-flex flex-column py-5'>
        <div className='d-flex mx-4 align-items-center'>
          <input
            type='checkbox'
            name='confirm'
            id='confirm'
            onChange={() => setConfirm((prev) => !prev)}
          />
          <span className='p-1 disabled'>
            {intl.formatMessage({
              id: 'YES_I_HAVE_IT',
            })}
          </span>
        </div>
        <button
          onClick={() => {
            setSteps(`generate`)
          }}
          disabled={!confirm}
          className={`btn btn-lg btn-primary w-100 my-3`}
        >
          <span>
            {intl.formatMessage({
              id: 'NEXT_SECOND',
            })}
          </span>
        </button>
        <button
          type='button'
          className='btn btn-lg btn-light-primary w-100'
          onClick={() => {
            setSteps(`welcome`)
          }}
        >
          {intl.formatMessage({
            id: 'BACK',
          })}
        </button>
      </div>
    </div>
  )
}

export default BeforeCreateTwoFactor
