import React from 'react'
import {toAbsoluteUrl} from '../../_metronic/helpers/AssetHelpers'

interface ITwoFactorLayout {
  children: React.ReactNode | Array<React.ReactNode>
}

function AuthLayout({children}: ITwoFactorLayout) {
  return (
    <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        <a href='#' className='mb-12'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logos/suuol-small.png')}
            className='h-45px'
          />
        </a>
        {children}
      </div>
    </div>
  )
}

export default AuthLayout
