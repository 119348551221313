import {Form, Formik, FormikProps, FieldProps, Field} from 'formik'
import * as Yup from 'yup'
import {LoaderButton} from 'app/components/buttons/loader-button/LoaderButton'
import {ITokenTwoFactorEmail} from '../interfaces/ITokenTwoFactor'
import {useIntl} from 'react-intl'

interface ITwoFactiorForm {
  emailHandleSubmit(
    values: ITokenTwoFactorEmail,
    {setErrors}: {setErrors: Function}
  ): void
  logoutUserHandler(): void
  isLoading: boolean
}

function TwoFactorEmailForm({
  emailHandleSubmit,
  logoutUserHandler,
  isLoading,
}: ITwoFactiorForm) {
  const initialValues = {email: ''}
  const intl = useIntl()

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(`INCORRECT_EMAIL`)
      .min(3, `MIN_IS_3`)
      .max(50, `MAX_IS_50`)
      .required(`EMAIL_IS_REQUIRED`),
  })
  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={emailHandleSubmit}
      >
        {(formik: FormikProps<ITokenTwoFactorEmail>) => (
          <Form className='' noValidate autoComplete='off'>
            <Field name='email'>
              {({field, form, meta}: FieldProps<ITokenTwoFactorEmail>) => {
                return (
                  <>
                    <label className='form-label fs-6 fw-bolder text-dark m-4'>
                      Email:
                    </label>
                    <input
                      type='text'
                      id='email'
                      className={`form-control form-control-lg form-control-solid mt-4 ${
                        formik.dirty && meta.error ? `mb-2` : `mb-5`
                      }`}
                      onChange={formik.handleChange}
                      value={formik.values.email}
                    />
                    <span className='text-danger my-0'>
                      {formik.dirty &&
                        meta.error &&
                        intl.formatMessage({
                          id: meta.error,
                        })}
                    </span>

                    <LoaderButton
                      label={intl.formatMessage({id: `CONTINUE`})}
                      buttonType='submit'
                      disabled={!meta.value || !!meta.error}
                      loading={isLoading}
                      classes={`btn-lg btn-primary w-100 ${
                        formik.dirty && meta.error ? `mt-2` : `mt-5`
                      }`}
                    />

                    <button
                      type='button'
                      className='btn btn-lg btn-light-primary w-100 my-5'
                      onClick={() => {
                        logoutUserHandler()
                      }}
                    >
                      {intl.formatMessage({id: `CANCEL`})}
                    </button>
                  </>
                )
              }}
            </Field>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default TwoFactorEmailForm
