import {useParams} from 'react-router-dom'
import {useToasts} from 'react-toast-notifications'
import {useState} from 'react'
import TwoFactorEmailAndPinForm from '../../forms/twoFactorEmailAndPinForm'
import {ITokenTwoFactorEmailAndPin} from '../../interfaces/ITokenTwoFactor'
import confirmTwoFactorAuthentication from '../../controllers/confirmResetTwoFactor'
import ResetTwoFactorMessage from '../../components/twoFactor/resetTwoFactorMessage'
import {useIntl} from 'react-intl'

function ResetTwoFactor() {
  const {addToast} = useToasts()
  let {id} = useParams<{id: string}>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showForm, setShowForm] = useState(true)
  const intl = useIntl()
  function confirmResetTwoFactornHandleSubmit(
    values: ITokenTwoFactorEmailAndPin,
    {
      setErrors,
    }: {
      setErrors: Function
    }
  ) {
    setIsLoading(true)

    if (id === null || id === undefined || id === '') {
      setErrors({
        email: intl.formatMessage({id: `FOLLOW_INSTRUCTION_FROM_YOUR_EMAIL`}),
      })
      addToast(
        intl.formatMessage({id: `ERROR`}) +
          `: ` +
          intl.formatMessage({id: `FOLLOW_INSTRUCTION_FROM_YOUR_EMAIL`}),
        {
          appearance: 'error',
        }
      )
      setIsLoading(false)
      return
    }
    confirmTwoFactorAuthentication(values.email, id, values.pin)
      .then((data) => {
        setIsLoading(false)
        const {resetTwoFactorCode} = data
        if (resetTwoFactorCode) {
          setShowForm((prev) => !prev)
          addToast(
            intl.formatMessage({id: `SUCCESS`}) +
              `: ` +
              intl.formatMessage({id: `RESET_TWO_FACTOR_AUTHENTICATION`}),
            {
              appearance: 'success',
            }
          )
        }
      })
      .catch((error) => {
        setIsLoading(false)
        setErrors({email: error.message})
        addToast(
          intl.formatMessage({id: `ERROR`}) +
            `: ` +
            intl.formatMessage({id: error.message}),
          {appearance: 'error'}
        )
      })
  }

  return (
    <>
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>
          {intl.formatMessage({id: `RESET_TWO_FACTOR_AUTHENTICATION`})}
        </h1>
        {showForm && (
          <div className='text-gray-400 fw-bold fs-4'>
            {intl.formatMessage({id: `RESET_2FA_FORM_TITLE`})}
          </div>
        )}
      </div>
      {showForm && (
        <TwoFactorEmailAndPinForm
          isLoading={isLoading}
          confirmResetTwoFactornHandleSubmit={
            confirmResetTwoFactornHandleSubmit
          }
        />
      )}
      {!showForm && <ResetTwoFactorMessage typeMessage='confirm' />}
    </>
  )
}

export default ResetTwoFactor
