/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useHistory} from 'react-router'
import {useToasts} from 'react-toast-notifications'
import {authService} from 'app/services/auth.service'
import {LoaderButton} from 'app/components/buttons/loader-button/LoaderButton'
import {useIntl} from 'react-intl'

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  changePassword: '',
  privacyPolicy: false,
}

const registrationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, 'MIN_3_SYMBOLS')
    .max(50, 'MAX_50_SYMBOLS')
    .required('FIRST_NAME_IS_REQUIRED'),
  email: Yup.string()
    .email('WRONG_EMAIL_FORMAT')
    .min(3, 'MIN_3_SYMBOLS')
    .max(50, 'MAX_50_SYMBOLS')
    .required('EMAIL_IS_REQUIRED'),
  lastName: Yup.string()
    .min(3, 'MIN_3_SYMBOLS')
    .max(50, 'MAX_50_SYMBOLS')
    .required('LAST_NAME_IS_REQUIRED'),
  password: Yup.string()
    .min(8, 'MIN_8_SYMBOLS')
    .max(50, 'MAX_50_SYMBOLS')
    .required('PASSWORD_IS_REQUIRED'),
  changePassword: Yup.string()
    .required('PASSWORD_CONFIRMATION_IS_REQUIRED')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref('password')],
        `PASSWORD_AND_CONFIRM_PASSWORD_DIDN'T_MATCH`
      ),
    }),
  privacyPolicy: Yup.bool().oneOf([true], 'FIELD_MUST_BE_CHECKED'),
})

export const Registration = () => {
  const [loading, setLoading] = useState(false)
  const intl = useIntl()
  const history = useHistory()
  const {addToast} = useToasts()

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values) => {
      setLoading(true)
      try {
        const {changePassword, ...payload} = values
        await authService.createUser(payload)
        addToast(
          intl.formatMessage({
            id: `NEW_USER_HAS_BEEN_REGISTERED_CONFIRMATION_MAIL_HAS_BEEN_SENT_TO_YOUR_MAILBOX`,
          }),
          {appearance: 'success'}
        )
        history.push('/')
      } catch (e) {
        addToast(
          intl.formatMessage({
            id: `PROVIDED_DATA_IS_INVALID`,
          }),
          {appearance: 'error'}
        )
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      onSubmit={formik.handleSubmit}
    >
      <div className='mb-10 text-center'>
        <h1 className='text-dark mb-3'>
          {intl.formatMessage({
            id: `CREATE_AN_ACCOUNT`,
          })}
        </h1>
        <div className='text-gray-400 fw-bold fs-4'>
          {intl.formatMessage({
            id: `ALREADY_HAVE_AN_ACCOUNT`,
          })}
          <Link
            to='/auth/forgot-password'
            className='link-primary fw-bolder'
            style={{marginLeft: '5px'}}
          >
            {intl.formatMessage({
              id: `FORGOT_PASSWORD`,
            })}
          </Link>
        </div>
      </div>

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      <div className='row fv-row mb-7'>
        <div className='col-xl-6'>
          <label className='form-label fw-bolder text-dark fs-6'>
            {intl.formatMessage({
              id: `FIRST_NAME`,
            })}
          </label>
          <input
            placeholder={intl.formatMessage({
              id: `FIRST_NAME`,
            })}
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('firstName')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid':
                  formik.touched.firstName && formik.errors.firstName,
              },
              {
                'is-valid':
                  formik.touched.firstName && !formik.errors.firstName,
              }
            )}
          />
          {formik.touched.firstName && formik.errors.firstName && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>
                  {formik.errors.firstName &&
                    intl.formatMessage({
                      id: formik.errors.firstName,
                    })}
                </span>
              </div>
            </div>
          )}
        </div>
        <div className='col-xl-6'>
          <div className='fv-row mb-5'>
            <label className='form-label fw-bolder text-dark fs-6'>
              {intl.formatMessage({
                id: `LAST_NAME`,
              })}
            </label>
            <input
              placeholder={intl.formatMessage({
                id: `LAST_NAME`,
              })}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('lastName')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid':
                    formik.touched.lastName && formik.errors.lastName,
                },
                {
                  'is-valid':
                    formik.touched.lastName && !formik.errors.lastName,
                }
              )}
            />
            {formik.touched.lastName && formik.errors.lastName && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>
                    {formik.errors.lastName &&
                      intl.formatMessage({
                        id: formik.errors.lastName,
                      })}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className='fv-row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6'>Email</label>
        <input
          placeholder='Email'
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>
                {formik.errors.email &&
                  intl.formatMessage({
                    id: formik.errors.email,
                  })}
                {}
              </span>
            </div>
          </div>
        )}
      </div>

      <div className='mb-10 fv-row' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>
            {intl.formatMessage({
              id: `PASSWORD`,
            })}
          </label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder={intl.formatMessage({
                id: `PASSWORD`,
              })}
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid':
                    formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid':
                    formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>
                    {formik.errors.password &&
                      intl.formatMessage({
                        id: formik.errors.password,
                      })}
                    {}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className='fv-row mb-5'>
        <label className='form-label fw-bolder text-dark fs-6'>
          {intl.formatMessage({
            id: `CONFIRM_PASSWORD`,
          })}
        </label>
        <input
          type='password'
          placeholder={intl.formatMessage({
            id: `CONFIRM_PASSWORD`,
          })}
          autoComplete='off'
          {...formik.getFieldProps('changePassword')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid':
                formik.touched.changePassword && formik.errors.changePassword,
            },
            {
              'is-valid':
                formik.touched.changePassword && !formik.errors.changePassword,
            }
          )}
        />
        {formik.touched.changePassword && formik.errors.changePassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>
                {formik.errors.changePassword &&
                  intl.formatMessage({
                    id: formik.errors.changePassword,
                  })}
              </span>
            </div>
          </div>
        )}
      </div>

      <div className='fv-row mb-5'>
        <input
          type='checkbox'
          id='privacy-policy-checkbox'
          {...formik.getFieldProps('privacyPolicy')}
          className={clsx(
            'mx-2',
            {
              'is-invalid':
                formik.touched.privacyPolicy && formik.errors.privacyPolicy,
            },
            {
              'is-valid':
                formik.touched.privacyPolicy && !formik.errors.privacyPolicy,
            }
          )}
        />
        <label
          className='form-label fw-bolder text-dark fs-6 '
          htmlFor='privacy-policy-checkbox'
        >
          <a href='/privacy-policy' target={'_blank'}>
            {intl.formatMessage({
              id: `PRIVACY_POLICY_ACCEPT`,
            })}
          </a>
          *
        </label>
        {formik.touched.privacyPolicy && formik.errors.privacyPolicy && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>
                {formik.errors.privacyPolicy &&
                  intl.formatMessage({
                    id: formik.errors.privacyPolicy,
                  })}
              </span>
            </div>
          </div>
        )}
      </div>

      <div className='text-center'>
        <LoaderButton
          label={intl.formatMessage({
            id: `SUBMIT`,
          })}
          buttonType='submit'
          disabled={formik.isSubmitting || !formik.isValid}
          loading={loading}
          classes='btn-lg btn-primary w-100 mb-5'
        />

        <Link to='/auth/login'>
          <button
            type='button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
          >
            {intl.formatMessage({
              id: `CANCEL`,
            })}
          </button>
        </Link>
      </div>
    </form>
  )
}
