import {useState} from 'react'
import {useDispatch} from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useHistory} from 'react-router'
import {useFormik} from 'formik'
import {User} from 'app/interfaces/user.interface'
import interceptor from '../../interceptor'
import {RootDispatcher} from 'app/store/root-dispatcher'
import {useToasts} from 'react-toast-notifications'
import {authService} from 'app/services/auth.service'
import {LoaderButton} from '../../components/buttons/loader-button/LoaderButton'
import {AxiosResponse} from 'axios'
import {useIntl} from 'react-intl'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('WRONG_EMAIL_FORMAT')
    .min(3, 'MIN_IS_3')
    .max(50, 'MAX_IS_50')
    .required('EMAIL_IS_REQUIRED'),
  password: Yup.string()
    .min(3, 'MIN_IS_3')
    .max(50, 'MAX_IS_50')
    .required('PASSWORD_IS_REQUIRED'),
})

const initialValues = {
  email: '',
  password: '',
}

export function Login() {
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const intl = useIntl()
  const {addToast} = useToasts()
  const dispatch = useDispatch()
  const rootDispatcher = new RootDispatcher(dispatch)
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        interceptor(null, null)
        const dataResponseUser: AxiosResponse<User> =
          await authService.loginUser(values)

        const {refreshToken, accessToken, ...user} = dataResponseUser.data

        localStorage.setItem('accessToken', dataResponseUser.data.accessToken)
        localStorage.setItem(
          'refreshToken',
          dataResponseUser.data.refreshToken.token
        )
        rootDispatcher.setUser(user, false)
        interceptor(dataResponseUser.data.accessToken, history.location)
      } catch (e) {
        addToast(
          intl.formatMessage({
            id: `PROVIDED_CREDENTIALS_ARE_INVALID`,
          }),
          {appearance: 'error'}
        )
        setLoading(false)
      }
    },
  })

  return (
    <form className='form w-100' onSubmit={formik.handleSubmit} noValidate>
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>
          {intl.formatMessage({
            id: `SIGN_IN_TO_SUUOL`,
          })}
        </h1>
        <div className='text-gray-400 fw-bold fs-4'>
          {intl.formatMessage({
            id: `NEW_HERE`,
          })}
          {` `}
          <Link to='/auth/registration' className='link-primary fw-bolder'>
            {intl.formatMessage({
              id: `CREATE_AN_ACCOUNT`,
            })}
          </Link>
        </div>
      </div>

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>
              {formik.errors.email &&
                intl.formatMessage({
                  id: formik.errors.email,
                })}
            </span>
          </div>
        )}
      </div>

      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>
              {intl.formatMessage({
                id: `PASSWORD`,
              })}
            </label>
            <Link
              to='/auth/forgot-password'
              className='link-primary fs-6 fw-bolder'
              style={{marginLeft: '5px'}}
            >
              {intl.formatMessage({
                id: `FORGOT_PASSWORD`,
              })}
            </Link>
          </div>
        </div>
        <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>
                {formik.errors.password &&
                  intl.formatMessage({
                    id: formik.errors.password,
                  })}
              </span>
            </div>
          </div>
        )}
      </div>

      <div className='text-center'>
        <LoaderButton
          label={intl.formatMessage({
            id: `CONTINUE`,
          })}
          buttonType='submit'
          disabled={formik.isSubmitting || !formik.isValid}
          loading={loading}
          classes='btn-lg btn-primary w-100 mb-5'
        />
      </div>
    </form>
  )
}
