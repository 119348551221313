import LoadingSpinner from 'app/components/loading-spinner/LoadingSpinner'
import {UserPartialNoTokens} from 'app/interfaces/user.interface'
import ContactPage from 'app/pages/contact/ContactPage'
import ShopPluginsInstructionPage from 'app/pages/shop-plugins/ShopPluginsInstructionPage'
import ShopPluginsPage from 'app/pages/shop-plugins/ShopPluginsPage'
import {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import StatisticsRoutes from './Statistics.routes'

interface IPrivateRoutes {
  user: UserPartialNoTokens
}

export function PrivateRoutes({user}: IPrivateRoutes) {
  const DashboardPage = lazy(() => import('../pages/dashboard/Dashboard'))
  const AccountPage = lazy(() => import('../pages/account/Account'))
  const MultipleEditColorQedPage = lazy(
    () => import('../pages/qedColors/multipleEditColorQedPage')
  )
  const ApplicationsPage = lazy(
    () => import('../pages/applications/Applications')
  )
  const ApplicationPage = lazy(() => import('../pages/application/Application'))
  const ApplicationResultsPage = lazy(
    () => import('../pages/recognition-results/RecognitionResults')
  )
  const UsersPage = lazy(() => import('../pages/users/Users'))
  const QedStatus = lazy(() => import('../pages/qedStatus/qedStatusPage'))
  const QedColors = lazy(() => import('../pages/qedColors/ColorsQedPage'))
  const NewQedColors = lazy(() => import('../pages/qedColors/NewColorQedPage'))
  const EditQedColors = lazy(
    () => import('../pages/qedColors/editColorQedPage')
  )
  const UserProfilePage = lazy(
    () => import('../pages/user-profile/UserProfile')
  )
  const RecognitionResultPage = lazy(
    () => import('../pages/recognition-result/RecognitionResult')
  )
  const SurveysPage = lazy(() => import('../pages/surveys/Surveys'))
  const LoggerPage = lazy(() => import('../pages/logger/LoggerPage'))
  const OrganizationsPage = lazy(
    () => import('../pages/oranizations/OrganizationsPage')
  )
  const OrganizationPage = lazy(
    () => import('../pages/oranizations/OrganizationPage')
  )
  const SurveyCreatorPage = lazy(
    () => import('../pages/survey-creator/SurveyCreatorPage')
  )
  const SurveyDesignPage = lazy(
    () => import('../pages/survey-design/SurveyDesignPage')
  )
  const RecognitionTestsPage = lazy(
    () => import('../pages/recognition-tests/RecognitionTestsPage')
  )

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Switch>
        <Route path='/dashboard' component={DashboardPage} />
        <Route path='/account' component={AccountPage} />
        <Route exact path='/organizations/:id' component={OrganizationPage} />
        <Route exact path='/organizations' component={OrganizationsPage} />
        <Route exact path='/applications' component={ApplicationsPage} />
        <Route exact path='/applications/:id' component={ApplicationPage} />
        <Route
          exact
          path='/survey-creator/:applicationId/:id'
          component={SurveyCreatorPage}
        />
        <Route
          exact
          path='/survey-design/:applicationId/:surveyHash'
          component={SurveyDesignPage}
        />
        <Route
          path='/applications/:id/results'
          component={ApplicationResultsPage}
        />
        <Route path='/results/:id' component={RecognitionResultPage} />
        <Route path='/statistics'>
          <StatisticsRoutes />
        </Route>
        {(user?.isAdmin ||
          (user?.access &&
            user?.access.find((a) => a.url === '/users')?.write)) && (
          <Route exact path='/users' component={UsersPage} />
        )}
        {user?.isAdmin && (
          <Route exact path='/qedstatus' component={QedStatus} />
        )}
        {user?.isAdmin && (
          <Route exact path='/qedstatus' component={QedStatus} />
        )}
        {user?.isAdmin && (
          <Route exact path='/qedcolors/edit/:id' component={EditQedColors} />
        )}
        {user?.isAdmin && (
          <Route exact path='/qedcolors/new' component={NewQedColors} />
        )}
        {user?.isAdmin && (
          <Route
            exact
            path='/qedcolors/multipleEditName/'
            component={MultipleEditColorQedPage}
          />
        )}
        {/* path='/qedcolors/list' */}
        {user?.isAdmin && (
          <Route exact path='/qedcolors' component={QedColors} />
        )}
        {user?.isAdmin && (
          <Route
            exact
            path='/recognition-tests'
            component={RecognitionTestsPage}
          />
        )}
        {user?.isAdmin && <Route path='/logger' component={LoggerPage} />}
        {user?.isAdmin && <Route path='/surveys' component={SurveysPage} />}
        {<Route exact path='/shop-plugins' component={ShopPluginsPage} />}
        {
          <Route
            exact
            path='/shop-plugins/how-to-install'
            component={ShopPluginsInstructionPage}
          />
        }
        <Route path='/contact' component={ContactPage} />
        <Route path='/users/:id' component={UserProfilePage} />
        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        {/* <Redirect to='error/404' /> */}
      </Switch>
    </Suspense>
  )
}
