import React from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import {App} from './app/App'
import {MetronicI18nProvider} from './app/i18n'
import {ToastProvider} from 'react-toast-notifications'
import {store} from './app/store'
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/style.react.scss'
import 'react-datepicker/dist/react-datepicker.css'
import pl from 'date-fns/locale/pl'
import {registerLocale} from 'react-datepicker'

registerLocale('pl', pl)

const {PUBLIC_URL} = process.env

ReactDOM.render(
  <MetronicI18nProvider>
    <Provider store={store}>
      <ToastProvider autoDismiss>
        <App basename={PUBLIC_URL} />
      </ToastProvider>
    </Provider>
  </MetronicI18nProvider>,
  document.getElementById('root')
)
