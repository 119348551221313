import axios from 'axios'
import { ErrorDataFetch } from '../models/ErrorDataFetch';
import { AxiosResponse, AxiosRequestConfig } from 'axios';
import { ICreateToFactor } from '../interfaces/ICreateToFactor';

export default async function createTwoFactorAuthentication():Promise<ICreateToFactor> {
    const accessToken = localStorage.getItem(`accessToken`);

    const config:AxiosRequestConfig  = {
        headers:{
            Authorization:`Bearer ${accessToken}`
        }
    }
    try {
        const data:AxiosResponse<ICreateToFactor> = await axios.post(`/api/auth/two-factor`,{},config)
               
        return data.data
    } catch (error:unknown) {
        
        if(axios.isAxiosError(error))
        if(error.code){
            throw new ErrorDataFetch(error.message, +error.code)
        }else{
            throw new ErrorDataFetch(error.message, 404);
        }
        throw new ErrorDataFetch(`error fetch data`, 404)
    }
}