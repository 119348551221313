import React from 'react'
import {Col, Row} from 'react-bootstrap-v5'
import {useIntl} from 'react-intl'
import {useHistory} from 'react-router-dom'
import {KTSVG} from '_metronic/helpers'

const StatisticsAdminMenu = () => {
  const intl = useIntl()
  const history = useHistory()

  const handleRedirect = (path: string) => history.push(path)

  return (
    <div className='d-flex flex-column h-100 pt-2'>
      <Row className='justify-content-between my-auto'>
        <Col
          sm={5}
          role='button'
          className='bg-light-warning px-6 py-6 rounded-2'
          onClick={() => handleRedirect('/statistics/applications')}
        >
          <KTSVG
            path='/media/icons/duotune/general/gen022.svg'
            className='svg-icon-3x svg-icon-warning d-block pb-3'
          />
          <span className='text-warning fw-bold fs-6 opacity-50'>
            {intl.formatMessage({id: 'APPLICATIONS'})}
          </span>
        </Col>
        <Col
          sm={5}
          role='button'
          className='px-6 py-6 rounded-2'
          style={{backgroundColor: '#EEE5FF'}}
          onClick={() => handleRedirect('/statistics/recognition')}
        >
          <KTSVG
            path='/media/icons/duotune/technology/teh001.svg'
            className='svg-icon-3x svg-icon-info d-block pb-3'
          />
          <span className='text-info fw-bold fs-6 opacity-50'>
            {intl.formatMessage({id: 'RECOGNITION'})}
          </span>
        </Col>
      </Row>

      <Row className='justify-content-between my-auto'>
        <Col
          sm={5}
          role='button'
          className='px-6 py-6 rounded-2 '
          style={{backgroundColor: '#FFE2E6'}}
          onClick={() => handleRedirect('/statistics/clothes-sets')}
        >
          <KTSVG
            path='/media/icons/duotune/abstract/abs017.svg'
            className='svg-icon-3x svg-icon-danger d-block pb-3'
          />
          <span className='text-danger fw-bold fs-6 mt-2 opacity-50'>
            {intl.formatMessage({id: 'CLOTHES_SETS'})}
          </span>
        </Col>
        <Col
          sm={5}
          role='button'
          className='px-6 py-6 rounded-2 '
          style={{backgroundColor: '#C9F7F5'}}
          onClick={() => handleRedirect('/statistics/surveys')}
        >
          <KTSVG
            path='/media/icons/duotune/abstract/abs027.svg'
            className='svg-icon-3x svg-icon-success d-block pb-3'
          />
          <span className='text-success fw-bold fs-6 mt-2 opacity-50'>
            {intl.formatMessage({id: 'SURVEYS'})}
          </span>
        </Col>
      </Row>
      <Row className='justify-content-center my-auto'>
        <Col
          // sm={10}
          role='button'
          className='px-6 py-6 rounded-2'
          style={{backgroundColor: '#B3E4FF'}}
          onClick={() => handleRedirect('/statistics/server')}
        >
          <KTSVG
            path='/media/icons/duotune/abstract/abs045.svg'
            className='svg-icon-3x svg-icon-primary d-block pb-3'
          />
          <span className='text-primary fw-bold fs-6 mt-2 opacity-50'>
            {intl.formatMessage({id: 'SERVER'})}
          </span>
        </Col>
      </Row>
    </div>
  )
}

export default StatisticsAdminMenu
