import axios from 'axios'
import { ErrorDataFetch } from '../models/ErrorDataFetch';
import { AxiosResponse } from 'axios';

export default async function logoutServer() {
   
    try {
        const data:Promise<AxiosResponse<boolean>> = axios.post(`/api/auth/logout`,{} )
    
        return Promise.resolve(data)
    } catch (error:unknown) {
        
        if(axios.isAxiosError(error))
        if(error.code){
            throw new ErrorDataFetch(error.message, +error.code)
        }else{
            throw new ErrorDataFetch(error.message, 404);
        }
        throw new ErrorDataFetch(`error fetch data`, 404)
    }
}