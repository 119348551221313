import { Notification } from 'app/interfaces/notification.interface';
import axios from 'axios'

const getNotifications = (params: any) => {
  return axios.get(`/api/notifications`, {params})
}

const getLatestNotifications = (params: any) => {
  return axios.get(`/api/notifications/latest`, {params})
}

const updateNotification = (id: string, payload: Partial<Notification>) => {
  return axios.put(`/api/notifications/${id}`, payload);
};

export const notificationService = {
  getNotifications,
  getLatestNotifications,
  updateNotification
}