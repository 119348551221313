interface ITwoFactorScreen {
  title: string
  subtitle?: string
  children: React.ReactNode | Array<React.ReactNode>
}

function AuthScreen({children, title, subtitle}: ITwoFactorScreen) {
  return (
    <div className='w-sm-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>{title}</h1>
        {subtitle && <h4 className='text-dark my-4'>{subtitle}</h4>}
        <div className='fv-row mb-10'>{children}</div>
      </div>
    </div>
  )
}

export default AuthScreen
