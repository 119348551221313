import axios from 'axios'
import { ErrorDataFetch } from '../models/ErrorDataFetch';
import { AxiosResponse } from 'axios';

import { IConfirmResetTwoFactor } from '../models/IConfirmResetTwoFactor';

export default async function confirmTwoFactorAuthentication(email:string, queryKey: string, pin: string):Promise<IConfirmResetTwoFactor> {

    
    try {
        const data:AxiosResponse<IConfirmResetTwoFactor> = await axios.post(`/api/auth/reset-two-factor/confirm`,
        { email, queryKey, pin },      
        )
               
        return data.data
    } catch (error:any) {
        if(error?.response?.data?.message && error?.response?.data?.code){           
            throw new ErrorDataFetch(error.response.data.message,error.response.data.code)
        }

        if(axios.isAxiosError(error))
        if(error.code){
            throw new ErrorDataFetch(error.message, +error.code)
        }else{
            throw new ErrorDataFetch(error.message, 404);
        }
        throw new ErrorDataFetch(`error fetch data`, 404)
    }
}