import axios, { AxiosRequestConfig } from 'axios'
import { IVerifyTwoFactor } from '../models/IVerifyTwoFactor';
import { ErrorDataFetch } from '../models/ErrorDataFetch';
import { AxiosResponse } from 'axios';

export default async function getVerifyTwoFactorAuthentication(tokenTwoFactor:string, accessToken:string):Promise<IVerifyTwoFactor> {

    const config:AxiosRequestConfig  = {
        headers:{
            Authorization:`Bearer ${accessToken}`
        }
    }
 
    try {
        const data:AxiosResponse<IVerifyTwoFactor> = await axios.post(`/api/auth/two-factor/verify`,
        { token:tokenTwoFactor }, 
        config
        )
               
        return data.data
    } catch (error:unknown) {
        
        if(axios.isAxiosError(error))
        if(error.code){
            throw new ErrorDataFetch(error.message, +error.code)
        }else{
            throw new ErrorDataFetch(error.message, 404);
        }
        throw new ErrorDataFetch(`error fetch data`, 404)
    }
}