import React, {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useHistory} from 'react-router'
import {useFormik} from 'formik'
import {useToasts} from 'react-toast-notifications'
import {authService} from 'app/services/auth.service'
import {LoaderButton} from 'app/components/buttons/loader-button/LoaderButton'
import {useDispatch} from 'react-redux'
import {RootDispatcher} from 'app/store/root-dispatcher'
import {authChannel} from 'app/App'
import {useIntl} from 'react-intl'
import {clearLocalStorageWithoutLanguage} from '../../utils/clearLocalStorageWithoutLanguage'

const initialValues = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('WRONG_EMAIL_FORMAT')
    .min(3, 'MIN_IS_3')
    .max(50, 'MAX_IS_50')
    .required('EMAIL_IS_REQUIRED'),
})

export const ForgotPassword = () => {
  const history = useHistory()
  const intl = useIntl()
  const dispatch = useDispatch()
  const rootDispatcher = new RootDispatcher(dispatch)
  const {addToast} = useToasts()
  const [loading, setLoading] = useState(false)
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        await authService.setForgottenPasswordToken({
          ...values,
          location: window.location,
        })
        addToast(
          intl.formatMessage({
            id: 'PASSWORD_RESET_INSTRUCTIONS_HAVE_BEEN_SENT_TO_YOUR_EMAIL',
          }),
          {
            appearance: 'success',
          }
        )
        rootDispatcher.setUser(null, false)
        clearLocalStorageWithoutLanguage()
        authChannel.postMessage({cmd: 'logout'})
        history.push('/')
      } catch (e) {
        addToast(
          intl.formatMessage({
            id: 'INCORRECT_CREDENTIALS',
          }),
          {appearance: 'error'}
        )
      } finally {
        setLoading(false)
      }
    },
  })

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          <h1 className='text-dark mb-3'>
            {intl.formatMessage({
              id: 'FORGOT_PASSWORD',
            })}
          </h1>
          <div className='text-gray-400 fw-bold fs-4'>
            {intl.formatMessage({
              id: 'ENTER_YOUR_EMAIL_TO_RESET_YOUR_PASSWORD',
            })}
          </div>
        </div>
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>
            Email
          </label>
          <input
            type='email'
            placeholder=''
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>
                  {formik.errors.email &&
                    intl.formatMessage({
                      id: formik.errors.email,
                    })}
                </span>
              </div>
            </div>
          )}
        </div>
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <LoaderButton
            label={intl.formatMessage({
              id: 'SUBMIT',
            })}
            buttonType='submit'
            disabled={false}
            loading={loading}
            classes='btn-lg btn-primary fw-bolder me-4'
          />
          <Link to='/auth/login'>
            <button
              type='button'
              className='btn btn-lg btn-light-primary fw-bolder'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {intl.formatMessage({
                id: 'CANCEL',
              })}
            </button>
          </Link>
        </div>
      </form>
    </>
  )
}
