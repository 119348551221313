import {Language} from 'app/enums/language.enum'
import {LatestNotifications} from 'app/interfaces/notification.interface'
import {Dispatch} from 'redux'
import {UserPartialNoTokens} from '../interfaces/user.interface'
import {ActionType, DispatchAction} from './root-reducer'

export class RootDispatcher {
  private readonly dispatch: Dispatch<DispatchAction>

  constructor(dispatch: Dispatch<DispatchAction>) {
    this.dispatch = dispatch
  }

  setUser = (user: UserPartialNoTokens, isVerifyTwoFactor: boolean) => {
    if (user === null || user === undefined) {
      this.dispatch({
        type: ActionType.SET_USER,
        payload: {user: null},
      })
    } else {
      this.dispatch({
        type: ActionType.SET_USER,
        payload: {user: {...user, isVerifyTwoFactor}},
      })
    }
  }

  setIsUseTwoFactor = (user: UserPartialNoTokens, isUsedTwoFactor: boolean) => {
    if (user === null || user === undefined) {
      this.dispatch({
        type: ActionType.SET_USER,
        payload: {user: null},
      })
    } else {
      this.dispatch({
        type: ActionType.SET_IS_USE_2FA,
        payload: {user: {...user, isUsedTwoFactor}},
      })
    }
  }
  setIsNewUser = (user: UserPartialNoTokens, isNewUser: boolean) => {
    if (user === null || user === undefined) {
      this.dispatch({
        type: ActionType.SET_USER,
        payload: {user: null},
      })
    } else {
      this.dispatch({
        type: ActionType.SET_IS_NEW_USER,
        payload: {user: {...user, isNewUser}},
      })
    }
  }

  setLanguage = (languageCode: Language) =>
    this.dispatch({
      type: ActionType.SET_LANGUAGE,
      payload: {language: languageCode},
    })

  setConfirmationModal = (isActive: boolean) =>
    this.dispatch({
      type: ActionType.SET_CONFIRMATION_MODAL,
      payload: {confirmationModal: isActive},
    })

  setNotifications = (notifications: LatestNotifications) =>
    this.dispatch({
      type: ActionType.SET_NOTIFICATIONS,
      payload: {notifications},
    })
}
