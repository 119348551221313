import clsx from 'clsx'
import {useState} from 'react'
import {toAbsoluteUrl} from '../../../helpers'
import {HeaderUserMenu} from '../../../partials'
import {KTSVG} from '../../../helpers/components/KTSVG'
import {HeaderNotificationsMenu} from '../../../partials'
import {LatestNotifications} from 'app/interfaces/notification.interface'
import {UserPartialNoTokens} from '../../../../app/interfaces/user.interface'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1'

interface ITopbar {
  user: UserPartialNoTokens
  notifications: LatestNotifications | null
}

function Topbar({notifications, user}: ITopbar) {
  const [isUserMenu, setIsUserMenu] = useState<boolean>(false)
  const [isNotificationsMenu, setIsNotificationsMenu] = useState<boolean>(false)
  const toggleUserMenu = () => setIsUserMenu((prev) => !prev)
  const unreadNotificationsNumber = notifications?.unread
  const toggleNotificationsMenu = () => setIsNotificationsMenu((prev) => !prev)

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
      >
        <div
          className={clsx(
            'btn btn-icon btn-active-light-primary position-relative',
            toolbarButtonHeightClass
          )}
          onClick={toggleNotificationsMenu}
        >
          <KTSVG
            path='/media/icons/duotune/general/gen022.svg'
            className={toolbarButtonIconSizeClass}
          />
          {unreadNotificationsNumber ? (
            <span
              className='badge badge-circle badge-danger'
              style={{
                position: 'absolute',
                fontSize: '10px',
                width: '14px',
                height: '14px',
                paddingTop: '3px',
                right: '0',
              }}
            >
              {unreadNotificationsNumber}
            </span>
          ) : null}
        </div>
        {isNotificationsMenu && (
          <HeaderNotificationsMenu
            notifications={notifications}
            hideMenu={toggleNotificationsMenu}
          />
        )}
      </div>
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        <div
          className={clsx(
            'cursor-pointer symbol',
            toolbarUserAvatarHeightClass
          )}
        >
          <img
            src={user?.avatar || toAbsoluteUrl('/media/avatars/blank.png')}
            alt='metronic'
            onClick={toggleUserMenu}
          />
          {isUserMenu && <HeaderUserMenu toggleUserMenu={toggleUserMenu} />}
        </div>
      </div>
    </div>
  )
}

export {Topbar}
