import React from 'react'
import ReactDatePicker, {ReactDatePickerProps} from 'react-datepicker'
import styled from 'styled-components'

const Wrapper = styled.div`
  max-width: 400px;
`

const StyledReactDatePicker = styled(ReactDatePicker)`
  border-radius: 5px;
  border: 1px solid lightgray;
  font-size: 14px;
  padding: 8px 10px;
`

const CustomDateRangePicker = (props: ReactDatePickerProps<'', true>) => {
  return (
    <Wrapper>
      <StyledReactDatePicker {...props} />
    </Wrapper>
  )
}

export default CustomDateRangePicker
