import React from 'react'

const SurveyNotActive = () => {
  return (
    <div className='text-center'>
      <h1 className='fw-bolder fs-4x text-gray-700 mb-10'>
        Ankieta nie jest już dostępna
      </h1>
    </div>
  )
}

export default SurveyNotActive
