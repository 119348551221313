import {PageTemplate} from 'app/components/page-template/PageTemplate'
import React from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '_metronic/layout/core'

const ContactPage = () => {
  const intl = useIntl()
  return (
    <PageTemplate>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({id: 'CONTACT'})}
      </PageTitle>
      {intl.formatMessage({id: 'CONTACT_TEXT'})}:{' '}
      <strong>kontakt@suuol.com</strong>
    </PageTemplate>
  )
}

export default ContactPage
