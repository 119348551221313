/* eslint-disable jsx-a11y/anchor-is-valid */
import {User} from 'app/interfaces/user.interface'
import React, {FC, useRef} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {RootDispatcher} from 'app/store/root-dispatcher'
import {InitialState} from 'app/store/root-reducer'
import {Languages} from './Languages'
import {UserMenuWrapper} from './HeaderUserMenu.styles'
import {logoutUser} from '../../../../app/controllers/logoutUser'
import useOnClickOutside from 'app/hooks/useOnClickOutside'

interface IProps {
  toggleUserMenu: () => void
}

const HeaderUserMenu: FC<IProps> = ({toggleUserMenu}) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const rootDispatcher = new RootDispatcher(dispatch)
  const user: User = useSelector<InitialState>(({user}) => user) as User
  const ref = useRef() as React.MutableRefObject<HTMLInputElement>
  useOnClickOutside(ref, toggleUserMenu)

  const logOutHandler = () => {
    logoutUser(rootDispatcher)
  }

  return (
    <UserMenuWrapper
      ref={ref}
      className='menu menu-column menu-gray-600  menu-state-primary fw-bold py-4 fs-6 w-275px'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {user.firstName} {user.lastName}
            </div>
            <span className='fw-bold text-muted fs-7'>{user.email}</span>
          </div>
        </div>
      </div>
      <div className='separator my-2'></div>
      <div className='menu-item px-5'>
        <Link
          to={'/account'}
          className='menu-link px-5'
          onClick={toggleUserMenu}
        >
          {intl.formatMessage({id: 'MY_ACCOUNT'})}
        </Link>
      </div>
      <div className='separator my-2'></div>
      <Languages />
      <div className='menu-item px-5'>
        <Link
          onClick={logOutHandler}
          to='/auth/login'
          className='menu-link px-5'
        >
          {intl.formatMessage({id: 'LOG_OUT'})}
        </Link>
      </div>
    </UserMenuWrapper>
  )
}

export {HeaderUserMenu}
