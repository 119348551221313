import {notificationService} from 'app/services/notification.service'
import {RootDispatcher} from 'app/store/root-dispatcher'
import {InitialState} from 'app/store/root-reducer'
import clsx from 'clsx'
import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Link, useLocation} from 'react-router-dom'
import {MenuComponent} from '../../../assets/ts/components'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {useLayout} from '../../core'
import {Header} from './Header'
import {DefaultTitle} from './page-title/DefaultTitle'
import {Topbar} from './Topbar'
import {useQuery} from 'react-query'

interface IHeaderWrapperProps {
  shadow?: boolean
}

export function HeaderWrapper({shadow = false}: IHeaderWrapperProps) {
  const user = useSelector((state: InitialState) => state.user)

  const notifications = useSelector(
    (state: InitialState) => state.notifications
  )

  const {pathname} = useLocation()
  const dispatch = useDispatch()
  const {config, classes, attributes} = useLayout()
  const {header, aside} = config

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [pathname])

  useQuery(
    'getLastNotyfication',
    async () => {
      const result = await notificationService.getLatestNotifications({
        userId: user?._id,
        itemsPerPage: 10,
      })
      return result.data
    },
    {
      refetchInterval: 5 * 60 * 1000,
      onSuccess: (data) => {
        const rootDispatcher = new RootDispatcher(dispatch)
        rootDispatcher.setNotifications(data)
      },
    }
  )

  return (
    <div
      id='kt_header'
      className={clsx(
        'header',
        classes.header.join(' '),
        `align-items-stretch ${shadow ? `shadow` : ``}`
      )}
      {...attributes.headerMenu}
    >
      <div
        className={clsx(
          classes.headerContainer.join(' '),
          'd-flex align-items-stretch justify-content-between'
        )}
      >
        {/* begin::Aside mobile toggle */}
        {aside.display && (
          <div
            className='d-flex align-items-center d-lg-none ms-n3 me-1'
            title='Show aside menu'
          >
            <div
              className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
              id='kt_aside_mobile_toggle'
            >
              <KTSVG
                path='/media/icons/duotune/abstract/abs015.svg'
                className='svg-icon-2x mt-1'
              />
            </div>
          </div>
        )}
        {/* end::Aside mobile toggle */}
        {/* begin::Logo */}
        {!aside.display && (
          <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
            <Link to='/dashboard' className='d-lg-none'>
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/suuol-small.png')}
                className='h-30px'
              />
            </Link>
          </div>
        )}
        {/* end::Logo */}

        {aside.display && (
          <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
            <Link to='/' className='d-lg-none'>
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/suuol-small.png')}
                className='h-30px'
              />
            </Link>
          </div>
        )}

        <div className='d-flex align-items-stretch justify-content-between flex-lg-grow-1'>
          {header.left === 'menu' && (
            <div className='d-flex align-items-stretch' id='kt_header_nav'>
              <Header />
            </div>
          )}

          {header.left === 'page-title' && (
            <div className='d-flex align-items-center' id='kt_header_nav'>
              <DefaultTitle />
            </div>
          )}

          {user && notifications && (
            <div className='d-flex align-items-stretch flex-shrink-0'>
              <Topbar user={user} notifications={notifications} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
