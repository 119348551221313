import {Redirect, Route, Switch} from 'react-router-dom'
import {Login} from './../pages/auth/Login'
import {Registration} from './../pages/auth/Registration'
import {ConfirmRegistration} from './../pages/auth/ConfirmRegistration'
import {ForgotPassword} from './../pages/auth/ForgotPassword'
import {ResetPassword} from './../pages/auth/ResetPassword'
import ResetTwoFactor from './../pages/twoFactor/ResetTwoFactor'

function AuthRoutes() {
  return (
    <Switch>
      <Route path='/auth/reset-two-factor/:id' component={ResetTwoFactor} />
      <Route path='/auth/login' component={Login} />
      <Route path='/auth/registration' component={Registration} />
      <Route
        path='/auth/confirm-registration'
        component={ConfirmRegistration}
      />
      <Route path='/auth/forgot-password' component={ForgotPassword} />
      <Route path='/auth/reset-password/:token' component={ResetPassword} />
      <Redirect from='/auth' exact={true} to='/auth/login' />
      <Redirect to='/auth/login' />
    </Switch>
  )
}

export default AuthRoutes
