import {PageTemplate} from 'app/components/page-template/PageTemplate'
import React from 'react'
import {useIntl} from 'react-intl'
import styled from 'styled-components'
import {PageTitle} from '_metronic/layout/core'

const Image = styled.img`
  max-width: 700px;
`

const ShopPluginsInstructionPage = () => {
  const intl = useIntl()
  return (
    <PageTemplate>
      <PageTitle>Instrukcja instalacji wtyczki</PageTitle>
      <h3>Prestashop</h3>
      <ol>
        <li>Zalogować się do panelu administratora w sklepie</li>
        <li>
          Przejść kolejno do: Ulepszenia{'>'} Moduły{'>'} Menedżer modułów
        </li>
        <li>Kliknąć “Załaduj moduł”</li>
        <p>
          <Image
            src={
              process.env.PUBLIC_URL +
              '/images/prestashop-instruction-modules-page.png'
            }
            alt='module'
          />
        </p>
        <li>Przeciągnąć pobrany plik .zip z wtyczką we wskazane miejsce</li>
        <Image
          src={
            process.env.PUBLIC_URL +
            '/images/prestashop-instruction-modules-modal.png'
          }
          alt='module'
        />
        <li>
          Wtyczka została zainstalowana, teraz należy połączyć ją z proxy, a
          następnie dokończyć konfigurację
        </li>
      </ol>
      <h3 className='mt-5'>Magento</h3>
      <p>
        Wymagana praca informatyka. Instalacja wtyczki odbywa się np. poprzez
        protokół SSH
      </p>
    </PageTemplate>
  )
}

export default ShopPluginsInstructionPage
