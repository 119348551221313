export function clearLocalStorageWithoutLanguage() {
  const lang = localStorage.getItem(`lang`)
  const i18nConfig = localStorage.getItem(`i18nConfig`)

  localStorage.clear()
  if (lang) {
    localStorage.setItem(`lang`, lang)
  }
  if (i18nConfig) {
    localStorage.setItem(`i18nConfig`, i18nConfig)
  }
}
