import styled from 'styled-components'

export const UserMenuWrapper = styled.div`
  z-index: 105;
  position: fixed;
  inset: 0px 0px auto auto;
  margin: 0;
  transform: translate(-30px, 65px);
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 50px 0 rgb(82 63 105 / 10%);
`
