import {ISurveyDesign} from 'app/interfaces/surveys.interface'
import React from 'react'
import {useHistory} from 'react-router-dom'
import {UniaImageWrapper, PrivacyPolicyWrapper} from '../Survey.styles'

interface IProps {
  surveyDesign?: ISurveyDesign
}

const KidihubFooter: React.FC<IProps> = ({surveyDesign}) => {
  const history = useHistory()
  return (
    <div>
      <UniaImageWrapper>
        {surveyDesign?.footerImageUrl && (
          <img src={surveyDesign?.footerImageUrl} alt='unia' />
        )}
      </UniaImageWrapper>
      <PrivacyPolicyWrapper>
        <p
          style={{cursor: 'pointer'}}
          onClick={() =>
            surveyDesign?.copyrightUrl
              ? window.open(surveyDesign?.copyrightUrl, '_blank')
              : history.push('/survey/privacy-policy')
          }
        >
          Polityka prywatności
        </p>
        <p>
          © {surveyDesign?.copyrightYear} {surveyDesign?.copyrightShopName}
        </p>
      </PrivacyPolicyWrapper>
    </div>
  )
}

export default KidihubFooter
